import React,{useState,useEffect} from 'react'
import PropertyCard from '../ListingCard/HotelCard'
import RentalCard from '../ListingCard/RentalCard'
import TourCard from '../ListingCard/TourCard'
import Pagination from '../Pagination'
import './properties-section.scss'
import { hotelData,topDestinations } from '../../DataFiles/Hotel'

const PropertiesSection = ({totalListing,heading,showTabs,activeTabs,showPagination}) => {
    const [tabs,setTabs] = useState(activeTabs || "Hotel")
    const [activeSearch,setActiveSearch] = useState("Hotel")
    const [ filterData, setFilterData ] = useState(hotelData || [])
  
    useEffect(() => {
      setFilterData(hotelData)
    }, [hotelData])
    
    //custom pagination
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(hotelData.length / totalListing);
    const startIndex = (currentPage - 1) * totalListing;
    const endIndex = startIndex + totalListing;
    const currentData = hotelData.slice(startIndex, endIndex);
    console.log("currentData",currentData)
    const handlePageChange = pageNumber => {
      setCurrentPage(pageNumber);
    };
  return (
    <div>
         <section className='recommended-places'>
          <div className='container'>
            <h2 className='recommend-heading'>{heading}</h2>
           {showTabs &&
            <div className='recommended-tabs'>
              <button className={tabs == "Hotel" ? "active-btn" : "not-active-btn" } onClick={() => setTabs("Hotel")}>Hotel</button>
              <button className={tabs == "Tour" ? "active-btn" : "not-active-btn" } onClick={()=>setTabs("Tour")}>Tour</button>
              <button className={tabs == "Activity" ? "active-btn" : "not-active-btn" } onClick={()=>setTabs("Activity")}>Activity</button>
              <button className={tabs == "Rental" ? "active-btn" : "not-active-btn" } onClick={()=>setTabs("Rental")}>Rental</button>
              <button className={tabs == "Car" ? "active-btn" : "not-active-btn"} onClick={()=>setTabs("Car")}>Car</button>
            </div>
            }
            <div className='recommended-container'>
            {
              currentData.map((hotel)=>(
                <div className='recommended-card'>
                  { 
                    tabs == "Hotel" ?
                      <PropertyCard hotel={hotel} />:
                      tabs == "Tour"?
                      <TourCard hotel={hotel} />:
                      tabs == "Activity"?
                      <TourCard hotel={hotel} />:
                      tabs == "Rental"?
                      <RentalCard hotel={hotel} type="Rental" />:
                      <RentalCard hotel={hotel} type="Car" />
                  }
                </div>
              ))
            }
            </div>
            {showPagination && 
              <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
          }
            </div>
        </section>
    </div>
  )
}

export default PropertiesSection