export const hotelData = [
    {
      title:"Hotel Astotel",
      location:"Nevada",
      star:5,
      offer:"10%",
      review:"Excellent",
      totalReview:"3 reviews",
      price:"$ 13",
      time:"night",
      avatar:"https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      background:"https://images.pexels.com/photos/157811/pexels-photo-157811.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
      title:"Hotel Astotel",
      location:"Nevada",
      star:5,
      review:"Excellent",
      totalReview:"3 reviews",
      price:"$ 13",
      time:"night",
      avatar:"https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      background:"https://images.pexels.com/photos/7349505/pexels-photo-7349505.jpeg?auto=compress&cs=tinysrgb&w=800",
    },
    {
      title:"Hotel Astotel",
      location:"Nevada",
      star:5,
      offer:"10%",
      review:"Excellent",
      totalReview:"3 reviews",
      price:"$ 13",
      time:"night",
      avatar:"https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      background:"https://images.pexels.com/photos/1693946/pexels-photo-1693946.jpeg?auto=compress&cs=tinysrgb&w=800",
    },

    {
        title:"Hotel Astotel",
        location:"Nevada",
        star:5,
        review:"Excellent",
        totalReview:"3 reviews",
        price:"$ 13",
        time:"night",
        avatar:"https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        background:"https://images.pexels.com/photos/256150/pexels-photo-256150.jpeg?auto=compress&cs=tinysrgb&w=800",
      },
      {
        title:"Hotel Astotel",
        location:"Nevada",
        star:5,
        review:"Excellent",
        totalReview:"3 reviews",
        price:"$ 13",
        time:"night",
        avatar:"https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        background:"https://images.pexels.com/photos/1500459/pexels-photo-1500459.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      },
    {
        title:"Hotel Astotel",
        location:"Nevada",
        star:5,
        review:"Excellent",
        totalReview:"3 reviews",
        price:"$ 13",
        time:"night",
        avatar:"https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        background:"https://images.pexels.com/photos/941195/pexels-photo-941195.jpeg?auto=compress&cs=tinysrgb&w=800",
      },
      {
        title:"Hotel Astotel",
        location:"Nevada",
        star:5,
        review:"Excellent",
        totalReview:"3 reviews",
        price:"$ 13",
        time:"night",
        avatar:"https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        background:"https://images.pexels.com/photos/164394/pexels-photo-164394.jpeg?auto=compress&cs=tinysrgb&w=800",
      },
      {
        title:"Hotel Astotel",
        location:"Nevada",
        star:5,
        review:"Excellent",
        totalReview:"3 reviews",
        price:"$ 13",
        time:"night",
        avatar:"https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        background:"https://images.pexels.com/photos/409842/pexels-photo-409842.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      },
      {
        title:"Hotel Astotel",
        location:"Nevada",
        star:5,
        review:"Excellent",
        totalReview:"3 reviews",
        price:"$ 13",
        time:"night",
        avatar:"https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        background:"https://images.pexels.com/photos/409842/pexels-photo-409842.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      },
      {
        title:"Hotel Astotel",
        location:"Nevada",
        star:5,
        review:"Excellent",
        totalReview:"3 reviews",
        price:"$ 13",
        time:"night",
        avatar:"https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        background:"https://images.pexels.com/photos/409842/pexels-photo-409842.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      },
      {
        title:"Hotel Astotel",
        location:"Nevada",
        star:5,
        review:"Excellent",
        totalReview:"3 reviews",
        price:"$ 13",
        time:"night",
        avatar:"https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        background:"https://images.pexels.com/photos/409842/pexels-photo-409842.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      },
  ]

 export let topDestinations = [
    {
      title:"California",
      Activity:2,
      id:1,
      car:3,
      hotel:4,
      rental:10,
      tours:10,
      img:"https://images.pexels.com/photos/2525903/pexels-photo-2525903.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
      desc:"California, constituent state of the United States of America. It was admitted as the 31st state of the union on September 9, 1850, and by the early 1960s it was the most populous U.S. state. No version of the origin of California’s name has been fully accepted, but there is wide support for the contention that it derived from an early 16th-century Spanish novel, Las sergas de Esplandián (“The Adventures of Esplandián”), that described a paradisiacal island full of gold and precious stones called California. The influence of the Spanish settlers of the 18th and 19th centuries is evident in California’s architecture and place-names. The capital is Sacramento.      "
    },
    {
      title:"New York",
      Activity:2,
      car:3,
      id:2,
      hotel:4,
      rental:10,
      tours:10,
      desc:"California, constituent state of the United States of America. It was admitted as the 31st state of the union on September 9, 1850, and by the early 1960s it was the most populous U.S. state. No version of the origin of California’s name has been fully accepted, but there is wide support for the contention that it derived from an early 16th-century Spanish novel, Las sergas de Esplandián (“The Adventures of Esplandián”), that described a paradisiacal island full of gold and precious stones called California. The influence of the Spanish settlers of the 18th and 19th centuries is evident in California’s architecture and place-names. The capital is Sacramento.",
      img:"https://images.pexels.com/photos/3074526/pexels-photo-3074526.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
    },
    {
      title:"Italy",
      Activity:2,
      car:3,
      id:3,
      hotel:4,
      rental:10,
      tours:10,
      desc:"California, constituent state of the United States of America. It was admitted as the 31st state of the union on September 9, 1850, and by the early 1960s it was the most populous U.S. state. No version of the origin of California’s name has been fully accepted, but there is wide support for the contention that it derived from an early 16th-century Spanish novel, Las sergas de Esplandián (“The Adventures of Esplandián”), that described a paradisiacal island full of gold and precious stones called California. The influence of the Spanish settlers of the 18th and 19th centuries is evident in California’s architecture and place-names. The capital is Sacramento.",
      img:"https://images.pexels.com/photos/1006965/pexels-photo-1006965.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
    },
    {
      title:"Ohio",
      Activity:2,
      car:3,
      id:4,
      hotel:4,
      rental:10,
      tours:10,
      desc:"California, constituent state of the United States of America. It was admitted as the 31st state of the union on September 9, 1850, and by the early 1960s it was the most populous U.S. state. No version of the origin of California’s name has been fully accepted, but there is wide support for the contention that it derived from an early 16th-century Spanish novel, Las sergas de Esplandián (“The Adventures of Esplandián”), that described a paradisiacal island full of gold and precious stones called California. The influence of the Spanish settlers of the 18th and 19th centuries is evident in California’s architecture and place-names. The capital is Sacramento.",
      img:"https://images.pexels.com/photos/16053726/pexels-photo-16053726/free-photo-of-picturesque-photo-of-new-york-at-sunset.jpeg?auto=compress&cs=tinysrgb&w=800",
    },
    {
      title:"Chicago",
      Activity:2,
      car:3,
      id:5,
      hotel:4,
      rental:10,
      tours:10,
      desc:"California, constituent state of the United States of America. It was admitted as the 31st state of the union on September 9, 1850, and by the early 1960s it was the most populous U.S. state. No version of the origin of California’s name has been fully accepted, but there is wide support for the contention that it derived from an early 16th-century Spanish novel, Las sergas de Esplandián (“The Adventures of Esplandián”), that described a paradisiacal island full of gold and precious stones called California. The influence of the Spanish settlers of the 18th and 19th centuries is evident in California’s architecture and place-names. The capital is Sacramento.",
      img:"https://images.pexels.com/photos/1770775/pexels-photo-1770775.jpeg?auto=compress&cs=tinysrgb&w=800",
    },
    {
      title:"Bali",
      Activity:2,
      desc:"California, constituent state of the United States of America. It was admitted as the 31st state of the union on September 9, 1850, and by the early 1960s it was the most populous U.S. state. No version of the origin of California’s name has been fully accepted, but there is wide support for the contention that it derived from an early 16th-century Spanish novel, Las sergas de Esplandián (“The Adventures of Esplandián”), that described a paradisiacal island full of gold and precious stones called California. The influence of the Spanish settlers of the 18th and 19th centuries is evident in California’s architecture and place-names. The capital is Sacramento.",
      car:3,
      id:5,
      hotel:4,
      rental:10,
      tours:10,
      img:"https://images.pexels.com/photos/3586966/pexels-photo-3586966.jpeg?auto=compress&cs=tinysrgb&w=800",
    },
  ]

  export let locations=["California","Navada","Chaicago","New York","Ohio","California","Navada","Chaicago","New York","Ohio","California","Navada","Chaicago","New York","Ohio"]

  export let facilities = [
    {
        title:"Airport Transport",
        icon:  <svg height="24px" width="24px" version="1.1"  x="0px" y="0px" viewBox="0 0 24 24" >
        <g fill="#5E6D77">
        <path d="M10.799,21.002c-0.553,0-1.086-0.205-1.501-0.578L7.52,18.823c-0.01-0.008-0.02-0.016-0.028-0.024l-1.243-1.115
                C6.1,17.55,6.012,17.366,6.001,17.165c-0.011-0.2,0.057-0.392,0.191-0.541c0.142-0.158,0.345-0.249,0.558-0.249
                c0.185,0,0.363,0.068,0.501,0.192l0.923,0.828l1.981-0.669l-2.341-2.795c-0.154-0.184-0.211-0.433-0.152-0.667
                c0.059-0.233,0.228-0.425,0.452-0.513l2.093-0.825c0.087-0.035,0.18-0.052,0.275-0.052c0.185,0,0.363,0.068,0.501,0.192
                l3.371,3.026l1.188-0.569c0.042-0.027,0.087-0.05,0.134-0.068c0.259-0.1,0.531-0.151,0.807-0.151c0.914,0,1.756,0.567,2.094,1.412
                c0.224,0.558,0.217,1.169-0.02,1.722c-0.236,0.552-0.673,0.98-1.231,1.204l-5.703,2.203C11.359,20.949,11.081,21.002,10.799,21.002
                z M10.302,19.309c0.138,0.124,0.315,0.192,0.498,0.192c0.094,0,0.188-0.018,0.277-0.053l5.698-2.202
                c0.179-0.072,0.323-0.214,0.402-0.398c0.079-0.184,0.081-0.388,0.006-0.573c-0.113-0.282-0.394-0.471-0.7-0.471
                c-0.07,0-0.139,0.01-0.206,0.029c-0.001,0.001-1.729,0.829-1.729,0.829c-0.1,0.048-0.212,0.074-0.324,0.074
                c-0.185,0-0.363-0.068-0.501-0.192l-3.4-3.052l-0.694,0.273l2.378,2.838c0.159,0.19,0.214,0.446,0.147,0.685
                c-0.067,0.239-0.247,0.428-0.482,0.507l-2.219,0.749L10.302,19.309z"></path>
        <path d="M2.25,24C1.009,24,0,22.991,0,21.75V5.25C0,4.009,1.009,3,2.25,3H6V0.75C6,0.336,6.336,0,6.75,0S7.5,0.336,7.5,0.75V3h9
                V0.75C16.5,0.336,16.836,0,17.25,0S18,0.336,18,0.75V3h3.75C22.991,3,24,4.009,24,5.25v16.5c0,1.241-1.009,2.25-2.25,2.25H2.25z
                M1.5,21.75c0,0.414,0.336,0.75,0.75,0.75h19.5c0.414,0,0.75-0.336,0.75-0.75V10.5h-21V21.75z M22.5,9V5.25
                c0-0.414-0.336-0.75-0.75-0.75H18V6c0,0.414-0.336,0.75-0.75,0.75S16.5,6.414,16.5,6V4.5h-9V6c0,0.414-0.336,0.75-0.75,0.75
                S6,6.414,6,6V4.5H2.25C1.836,4.5,1.5,4.836,1.5,5.25V9H22.5z"></path>
        </g>
        </svg>,
    },
    {
        title:"Fitness Center ",
        icon: <svg height="24px" width="24px" version="1.1"  x="0px" y="0px" viewBox="0 0 24 24"  >
        <g fill="#5E6D77">
        <path d="M9.88,9.985c-0.2,0-0.388-0.078-0.53-0.219c-0.293-0.293-0.293-0.768,0-1.061c0.877-0.877,0.877-2.304,0-3.182
                c-1.462-1.462-1.462-3.842,0-5.304C9.491,0.078,9.68,0,9.88,0s0.389,0.078,0.53,0.22c0.142,0.142,0.22,0.33,0.22,0.53
                c0,0.2-0.078,0.389-0.22,0.53c-0.877,0.877-0.877,2.305,0,3.182c1.462,1.462,1.462,3.841,0,5.303
                C10.269,9.907,10.08,9.985,9.88,9.985z"></path>
        <path d="M13.887,9.985c-0.2,0-0.388-0.078-0.53-0.219c-0.293-0.293-0.293-0.769,0-1.061c0.877-0.877,0.877-2.304,0-3.181
                c-1.462-1.462-1.462-3.842,0-5.304C13.498,0.078,13.687,0,13.887,0s0.389,0.078,0.53,0.22c0.142,0.142,0.22,0.33,0.22,0.53
                c0,0.2-0.078,0.389-0.22,0.53c-0.425,0.425-0.659,0.99-0.659,1.591c0,0.601,0.234,1.166,0.659,1.591
                c1.462,1.462,1.462,3.841,0,5.303C14.276,9.907,14.087,9.985,13.887,9.985z"></path>
        <path d="M17.894,9.985c-0.2,0-0.388-0.078-0.53-0.219c-0.142-0.142-0.22-0.33-0.22-0.531c0-0.2,0.078-0.389,0.22-0.53
                c0.877-0.877,0.877-2.304,0-3.181c-1.462-1.462-1.462-3.842,0-5.304C17.505,0.078,17.694,0,17.894,0s0.389,0.078,0.53,0.22
                c0.142,0.142,0.22,0.33,0.22,0.53c0,0.2-0.078,0.389-0.22,0.53c-0.877,0.877-0.877,2.305,0,3.182c1.462,1.462,1.462,3.841,0,5.303
                C18.283,9.907,18.094,9.985,17.894,9.985z"></path>
        <path d="M18.75,19.5c-0.414,0-0.75-0.336-0.75-0.75v-6c0-0.414,0.336-0.75,0.75-0.75s0.75,0.336,0.75,0.75v6
                C19.5,19.164,19.164,19.5,18.75,19.5z"></path>
        <path d="M12.75,19.5c-0.414,0-0.75-0.336-0.75-0.75v-6c0-0.414,0.336-0.75,0.75-0.75s0.75,0.336,0.75,0.75v6
                C13.5,19.164,13.164,19.5,12.75,19.5z"></path>
        <path d="M15.75,19.5c-0.414,0-0.75-0.336-0.75-0.75v-6c0-0.414,0.336-0.75,0.75-0.75s0.75,0.336,0.75,0.75v6
                C16.5,19.164,16.164,19.5,15.75,19.5z"></path>
        <path d="M9.75,19.5C9.336,19.5,9,19.164,9,18.75v-6C9,12.336,9.336,12,9.75,12s0.75,0.336,0.75,0.75v6
                C10.5,19.164,10.164,19.5,9.75,19.5z"></path>
        <circle cx="4.5" cy="9" r="1.125"></circle>
        <circle cx="4.5" cy="13.5" r="1.125"></circle>
        <path d="M20.25,24c-0.414,0-0.75-0.336-0.75-0.75V22.5h-15v0.75C4.5,23.664,4.164,24,3.75,24S3,23.664,3,23.25V22.5H2.25
                C1.009,22.5,0,21.491,0,20.25V6.75C0,5.509,1.009,4.5,2.25,4.5h3C5.664,4.5,6,4.836,6,5.25S5.664,6,5.25,6h-3
                C1.836,6,1.5,6.336,1.5,6.75v13.5C1.5,20.664,1.836,21,2.25,21h19.5c0.414,0,0.75-0.336,0.75-0.75V6.75
                C22.5,6.336,22.164,6,21.75,6S21,5.664,21,5.25s0.336-0.75,0.75-0.75C22.991,4.5,24,5.509,24,6.75v13.5
                c0,1.241-1.009,2.25-2.25,2.25H21v0.75C21,23.664,20.664,24,20.25,24z"></path>
        </g>
        </svg>,
    },
    {
        title:"Heater",
        icon:  <svg height="24px" width="24px" version="1.1"  x="0px" y="0px" viewBox="0 0 24 24"  >
        <g fill="#5E6D77">
        <path d="M9.88,9.985c-0.2,0-0.388-0.078-0.53-0.219c-0.293-0.293-0.293-0.768,0-1.061c0.877-0.877,0.877-2.304,0-3.182
                c-1.462-1.462-1.462-3.842,0-5.304C9.491,0.078,9.68,0,9.88,0s0.389,0.078,0.53,0.22c0.142,0.142,0.22,0.33,0.22,0.53
                c0,0.2-0.078,0.389-0.22,0.53c-0.877,0.877-0.877,2.305,0,3.182c1.462,1.462,1.462,3.841,0,5.303
                C10.269,9.907,10.08,9.985,9.88,9.985z"></path>
        <path d="M13.887,9.985c-0.2,0-0.388-0.078-0.53-0.219c-0.293-0.293-0.293-0.769,0-1.061c0.877-0.877,0.877-2.304,0-3.181
                c-1.462-1.462-1.462-3.842,0-5.304C13.498,0.078,13.687,0,13.887,0s0.389,0.078,0.53,0.22c0.142,0.142,0.22,0.33,0.22,0.53
                c0,0.2-0.078,0.389-0.22,0.53c-0.425,0.425-0.659,0.99-0.659,1.591c0,0.601,0.234,1.166,0.659,1.591
                c1.462,1.462,1.462,3.841,0,5.303C14.276,9.907,14.087,9.985,13.887,9.985z"></path>
        <path d="M17.894,9.985c-0.2,0-0.388-0.078-0.53-0.219c-0.142-0.142-0.22-0.33-0.22-0.531c0-0.2,0.078-0.389,0.22-0.53
                c0.877-0.877,0.877-2.304,0-3.181c-1.462-1.462-1.462-3.842,0-5.304C17.505,0.078,17.694,0,17.894,0s0.389,0.078,0.53,0.22
                c0.142,0.142,0.22,0.33,0.22,0.53c0,0.2-0.078,0.389-0.22,0.53c-0.877,0.877-0.877,2.305,0,3.182c1.462,1.462,1.462,3.841,0,5.303
                C18.283,9.907,18.094,9.985,17.894,9.985z"></path>
        <path d="M18.75,19.5c-0.414,0-0.75-0.336-0.75-0.75v-6c0-0.414,0.336-0.75,0.75-0.75s0.75,0.336,0.75,0.75v6
                C19.5,19.164,19.164,19.5,18.75,19.5z"></path>
        <path d="M12.75,19.5c-0.414,0-0.75-0.336-0.75-0.75v-6c0-0.414,0.336-0.75,0.75-0.75s0.75,0.336,0.75,0.75v6
                C13.5,19.164,13.164,19.5,12.75,19.5z"></path>
        <path d="M15.75,19.5c-0.414,0-0.75-0.336-0.75-0.75v-6c0-0.414,0.336-0.75,0.75-0.75s0.75,0.336,0.75,0.75v6
                C16.5,19.164,16.164,19.5,15.75,19.5z"></path>
        <path d="M9.75,19.5C9.336,19.5,9,19.164,9,18.75v-6C9,12.336,9.336,12,9.75,12s0.75,0.336,0.75,0.75v6
                C10.5,19.164,10.164,19.5,9.75,19.5z"></path>
        <circle cx="4.5" cy="9" r="1.125"></circle>
        <circle cx="4.5" cy="13.5" r="1.125"></circle>
        <path d="M20.25,24c-0.414,0-0.75-0.336-0.75-0.75V22.5h-15v0.75C4.5,23.664,4.164,24,3.75,24S3,23.664,3,23.25V22.5H2.25
                C1.009,22.5,0,21.491,0,20.25V6.75C0,5.509,1.009,4.5,2.25,4.5h3C5.664,4.5,6,4.836,6,5.25S5.664,6,5.25,6h-3
                C1.836,6,1.5,6.336,1.5,6.75v13.5C1.5,20.664,1.836,21,2.25,21h19.5c0.414,0,0.75-0.336,0.75-0.75V6.75
                C22.5,6.336,22.164,6,21.75,6S21,5.664,21,5.25s0.336-0.75,0.75-0.75C22.991,4.5,24,5.509,24,6.75v13.5
                c0,1.241-1.009,2.25-2.25,2.25H21v0.75C21,23.664,20.664,24,20.25,24z"></path>
        </g>
        </svg>,
    },
    {
        title:"Internet – Wifi",
        icon: <svg height="24px" width="24px" version="1.1"  x="0px" y="0px" viewBox="0 0 24 24">
        <g fill="#5E6D77">
        <path d="M12.001,14.202c-1.241,0-2.25-1.01-2.25-2.25s1.009-2.25,2.25-2.25s2.25,1.009,2.25,2.25S13.241,14.202,12.001,14.202z
                M12.001,11.202c-0.414,0-0.75,0.336-0.75,0.75c0,0.414,0.336,0.75,0.75,0.75c0.414,0,0.75-0.336,0.75-0.75
                C12.751,11.538,12.414,11.202,12.001,11.202z"></path>
        <path d="M15.979,16.303c-0.096,0-0.191-0.036-0.264-0.109l-0.532-0.529c-0.071-0.07-0.11-0.166-0.111-0.265s0.039-0.195,0.11-0.266
                c0.851-0.851,1.319-1.981,1.319-3.183s-0.469-2.331-1.319-3.181c-0.142-0.142-0.22-0.33-0.22-0.531c0-0.2,0.078-0.389,0.22-0.53
                c0.142-0.142,0.33-0.22,0.53-0.22c0.2,0,0.388,0.078,0.53,0.219c1.133,1.133,1.758,2.639,1.758,4.242
                c0,1.603-0.624,3.109-1.756,4.243C16.171,16.267,16.075,16.303,15.979,16.303z"></path>
        <path d="M8.289,16.415c-0.2,0-0.389-0.078-0.53-0.22C6.625,15.063,6.001,13.557,6,11.954c0-1.602,0.623-3.109,1.756-4.242
                C7.777,7.691,7.8,7.671,7.82,7.654C7.953,7.548,8.119,7.49,8.288,7.49c0.229,0,0.443,0.103,0.586,0.281
                C9.119,8.078,9.09,8.513,8.806,8.783c-1.742,1.748-1.74,4.6,0.013,6.352c0.142,0.141,0.22,0.33,0.22,0.53s-0.078,0.389-0.219,0.531
                C8.677,16.337,8.489,16.415,8.289,16.415z"></path>
        <path d="M17.835,18.536c-0.2,0-0.389-0.078-0.53-0.22c-0.142-0.141-0.22-0.33-0.22-0.53c0-0.2,0.078-0.389,0.22-0.53
                c2.923-2.924,2.923-7.682,0-10.607c-0.292-0.292-0.292-0.768,0-1.061c0.142-0.142,0.33-0.22,0.53-0.22s0.389,0.078,0.53,0.22
                c3.508,3.509,3.508,9.219,0,12.728C18.223,18.458,18.035,18.536,17.835,18.536z"></path>
        <path d="M6.167,18.536c-0.2,0-0.389-0.078-0.53-0.22C3.936,16.617,3,14.357,3,11.953c0-2.401,0.934-4.659,2.63-6.359
                c0.147-0.148,0.336-0.226,0.536-0.226s0.389,0.078,0.53,0.22c0.284,0.284,0.295,0.74,0.023,1.036
                C6.716,6.629,6.7,6.647,6.696,6.651C5.28,8.067,4.5,9.95,4.5,11.953s0.78,3.886,2.197,5.302c0.142,0.142,0.22,0.33,0.22,0.53
                s-0.078,0.389-0.22,0.53C6.555,18.458,6.367,18.536,6.167,18.536z"></path>
        <path d="M19.955,20.66c-0.2,0-0.389-0.078-0.53-0.22s-0.22-0.33-0.22-0.53s0.078-0.389,0.22-0.53c4.093-4.094,4.093-10.756,0-14.85
                c-0.142-0.142-0.22-0.33-0.22-0.53c0-0.2,0.078-0.389,0.22-0.53s0.33-0.22,0.53-0.22s0.389,0.078,0.53,0.22
                c4.678,4.679,4.678,12.292,0,16.971C20.344,20.582,20.156,20.66,19.955,20.66z"></path>
        <path d="M4.046,20.657c-0.2,0-0.389-0.078-0.53-0.22c-4.675-4.678-4.675-12.29,0-16.968c0.142-0.142,0.33-0.22,0.53-0.22
                s0.389,0.078,0.53,0.219c0.293,0.293,0.293,0.768,0,1.061c-4.091,4.093-4.091,10.754,0,14.847c0.142,0.142,0.219,0.33,0.219,0.531
                s-0.078,0.389-0.22,0.53C4.434,20.579,4.246,20.657,4.046,20.657z"></path>
        </g>
        </svg>,
    },
    {
        title:"Restaurant",
        icon:<svg height="24px" width="24px" version="1.1" id="Regular"  x="0px" y="0px" viewBox="0 0 24 24">
        <g fill="#5E6D77">
        <path d="M18.75,21.75c-1.067,0-2.061-0.582-2.594-1.5H7.844c-0.533,0.918-1.527,1.5-2.594,1.5s-2.061-0.582-2.594-1.5H2.25
                C1.009,20.25,0,19.241,0,18v-5.25c0-0.058,0.007-0.116,0.022-0.177c0.002-0.01,0.01-0.035,0.013-0.045
                c0.012-0.038,0.026-0.073,0.042-0.106c0.007-0.017,0.016-0.034,0.023-0.048l2.572-4.47C3.062,7.195,3.816,6.75,4.638,6.75H6
                c0.414,0,0.75,0.336,0.75,0.75S6.414,8.25,6,8.25H4.638c-0.274,0-0.526,0.149-0.657,0.389L2.047,12H7.5v-0.75
                c0-0.414,0.336-0.75,0.75-0.75S9,10.836,9,11.25v7.5h6.75c0-1.654,1.346-3,3-3s3,1.346,3,3c0.414,0,0.75-0.336,0.75-0.75v-7.5
                c0-0.414,0.336-0.75,0.75-0.75S24,10.086,24,10.5V18c0,1.241-1.009,2.25-2.25,2.25h-0.406C20.811,21.168,19.817,21.75,18.75,21.75z
                 M18.75,17.25c-0.827,0-1.5,0.673-1.5,1.5s0.673,1.5,1.5,1.5s1.5-0.673,1.5-1.5S19.577,17.25,18.75,17.25z M5.25,17.25
                c-0.827,0-1.5,0.673-1.5,1.5s0.673,1.5,1.5,1.5s1.5-0.673,1.5-1.5S6.077,17.25,5.25,17.25z M1.5,18c0,0.414,0.336,0.75,0.75,0.75
                c0-1.654,1.346-3,3-3c0.895,0,1.7,0.394,2.25,1.018V13.5h-6V18z"></path>
        <path d="M21.75,9c-0.563,0-1.091-0.206-1.5-0.575C19.841,8.794,19.313,9,18.75,9s-1.091-0.206-1.5-0.575
                C16.841,8.794,16.313,9,15.75,9s-1.091-0.206-1.5-0.575C13.841,8.794,13.313,9,12.75,9c-0.563,0-1.091-0.206-1.5-0.575
                C10.841,8.794,10.313,9,9.75,9C8.509,9,7.5,7.991,7.5,6.75c0-0.096,0.018-0.189,0.054-0.278l1.5-3.75
                C9.168,2.435,9.441,2.25,9.75,2.25h12c0.309,0,0.582,0.185,0.696,0.472l1.5,3.75C23.982,6.56,24,6.654,24,6.75
                C24,7.991,22.991,9,21.75,9z M20.25,6C20.664,6,21,6.336,21,6.75s0.336,0.75,0.75,0.75c0.371,0,0.683-0.271,0.74-0.63l-1.248-3.12
                H10.258L9.01,6.87C9.067,7.229,9.379,7.5,9.75,7.5c0.414,0,0.75-0.336,0.75-0.75S10.836,6,11.25,6S12,6.336,12,6.75
                s0.336,0.75,0.75,0.75s0.75-0.336,0.75-0.75S13.836,6,14.25,6S15,6.336,15,6.75s0.336,0.75,0.75,0.75s0.75-0.336,0.75-0.75
                S16.836,6,17.25,6S18,6.336,18,6.75s0.336,0.75,0.75,0.75s0.75-0.336,0.75-0.75S19.836,6,20.25,6z"></path>
        <path d="M11.25,14.25c-0.414,0-0.75-0.336-0.75-0.75v-3c0-0.414,0.336-0.75,0.75-0.75S12,10.086,12,10.5v2.25h7.5V10.5
                c0-0.414,0.336-0.75,0.75-0.75S21,10.086,21,10.5v3c0,0.414-0.336,0.75-0.75,0.75H11.25z"></path>
        </g>
        </svg>
    },
    {
        title:"Spa & Sauna ",
        icon:<svg height="24px" width="24px" version="1.1"  x="0px" y="0px" viewBox="0 0 24 24">
        <g fill="#5E6D77">
        <path d="M11.991,21.63c-6.723,0-11.993-2.635-11.993-6c0-1.481,1.179-3.046,3.035-4.065c-0.769-1.362-1.15-2.905-1.106-4.49
                c0.005-0.809,0.678-1.473,1.499-1.473l0.101-0.001c1.506,0,2.988,0.371,4.318,1.077c0.434-1.493,1.248-2.83,2.374-3.891
                c0.269-0.264,0.636-0.413,1.027-0.413c0.393,0,0.763,0.15,1.042,0.423c1.1,1.035,1.929,2.397,2.364,3.872
                c1.327-0.701,2.794-1.069,4.273-1.069l0.111,0.001c0.814,0.001,1.485,0.669,1.491,1.489c0.038,1.345-0.242,2.689-0.814,3.906
                c2.73,1.141,4.286,2.816,4.286,4.634c-0.001,0.333-0.052,0.661-0.152,0.976c-0.099,0.314-0.387,0.524-0.715,0.524h-3.633v2.738
                c0,0.328-0.21,0.616-0.523,0.715c-2.194,0.696-4.473,1.048-6.774,1.048L11.991,21.63z M3.889,12.811
                c-1.421,0.743-2.391,1.875-2.391,2.82c0,2.397,4.906,4.5,10.5,4.5c0.082,0.001,0.16,0.001,0.238,0.001
                c1.939,0,3.876-0.276,5.762-0.82V16.38c0-0.414,0.336-0.75,0.75-0.75h3.75c0-0.001,0-0.002,0-0.003
                c0-1.183-1.352-2.442-3.548-3.322c-0.243,0.344-0.509,0.67-0.793,0.969c-1.67,1.554-3.815,2.4-6.057,2.4
                c-0.278,0-0.564-0.014-0.852-0.042c-0.289,0.028-0.578,0.042-0.859,0.042c-2.25,0-4.4-0.84-6.054-2.365
                c-0.012-0.011-0.025-0.024-0.037-0.037C4.158,13.128,4.021,12.973,3.889,12.811z M5.369,12.225
                c1.135,1.039,2.552,1.694,4.064,1.888c-1.184-1.477-1.864-3.294-1.935-5.206c0.006-0.231,0.02-0.454,0.043-0.675
                C6.326,7.492,4.948,7.102,3.539,7.102L3.435,7.102C3.373,9.021,4.063,10.837,5.369,12.225z M14.956,8.222
                c0.022,0.214,0.036,0.428,0.042,0.638c-0.07,1.944-0.75,3.771-1.933,5.251c1.504-0.198,2.913-0.857,4.037-1.901
                c1.289-1.363,1.979-3.178,1.925-5.092l-0.042-0.015C17.562,7.102,16.175,7.489,14.956,8.222z M11.248,3.88
                c-1.381,1.306-2.198,3.134-2.25,5.021c0.07,1.863,0.885,3.667,2.25,4.98c1.365-1.314,2.181-3.128,2.25-5.028
                C13.447,6.999,12.626,5.178,11.248,3.88L11.248,3.88z"></path>
        </g>
        </svg>,
    },
    {
        title:"Washer & Dryer",
        icon:<svg height="24px" width="24px" version="1.1" x="0px" y="0px" viewBox="0 0 24 24" >
        <g fill="#5E6D77">
        <path d="M7.061,24c-0.812,0-1.623-0.192-2.348-0.555L2.981,22.58c-0.104-0.052-0.22-0.08-0.334-0.08H0.749
                c-0.414,0-0.75-0.336-0.75-0.75S0.336,21,0.749,21h1.896c0.349,0,0.696,0.083,1.007,0.239l1.732,0.865
                C5.902,22.363,6.481,22.5,7.06,22.5h11.69c0.414,0,0.75-0.336,0.75-0.75S19.163,21,18.749,21h-3c-0.414,0-0.75-0.336-0.75-0.75
                s0.336-0.75,0.75-0.75h5.25c0.414,0,0.75-0.336,0.75-0.75S21.413,18,20.999,18h-4.5c-0.414,0-0.75-0.336-0.75-0.75
                s0.336-0.75,0.75-0.75h5.25c0.414,0,0.75-0.336,0.75-0.75S22.163,15,21.749,15h-5.25c-0.414,0-0.75-0.336-0.75-0.75
                s0.336-0.75,0.75-0.75h3.75c0.414,0,0.75-0.336,0.75-0.75S20.663,12,20.249,12h-7.5c-0.414,0-0.75-0.336-0.75-0.75
                s0.336-0.75,0.75-0.75h2.25c0.414,0,0.75-0.336,0.75-0.75S15.413,9,14.999,9H9.157C7.9,9,6.734,9.623,6.037,10.667l-1.212,1.829
                C4.406,13.125,3.705,13.5,2.951,13.5H0.749c-0.414,0-0.75-0.336-0.75-0.75S0.336,12,0.749,12h2.202
                c0.251,0,0.484-0.125,0.624-0.334l1.213-1.83C5.766,8.372,7.398,7.5,9.155,7.5h5.844c1.241,0,2.25,1.009,2.25,2.25
                c0,0.259-0.044,0.511-0.129,0.75h3.129c1.241,0,2.25,1.009,2.25,2.25c0,0.288-0.054,0.567-0.159,0.829
                c0.956,0.261,1.659,1.135,1.659,2.171c0,0.774-0.405,1.487-1.042,1.895c0.19,0.335,0.292,0.716,0.292,1.105
                c0,1.241-1.009,2.25-2.25,2.25H20.87c0.085,0.239,0.129,0.491,0.129,0.75c0,1.241-1.009,2.25-2.25,2.25H7.061z"></path>
        <path d="M8.679,2.965c-0.053,0-0.107-0.006-0.16-0.017C8.114,2.859,7.857,2.458,7.946,2.054l0.321-1.465
                C8.342,0.248,8.65,0,8.999,0c0.054,0,0.107,0.006,0.16,0.017C9.564,0.106,9.821,0.506,9.732,0.91L9.411,2.375
                C9.336,2.717,9.028,2.965,8.679,2.965z"></path>
        <path d="M7.867,6.666c-0.053,0-0.107-0.006-0.16-0.017C7.302,6.56,7.045,6.159,7.134,5.755l0.169-0.77
                C7.379,4.638,7.68,4.396,8.035,4.396c0.054,0,0.108,0.006,0.161,0.017C8.6,4.502,8.857,4.903,8.768,5.306l-0.169,0.77
                C8.524,6.418,8.216,6.666,7.867,6.666z"></path>
        <path d="M4.606,2.855c-0.112,0-0.22-0.024-0.322-0.073C4.104,2.696,3.967,2.545,3.9,2.357C3.833,2.168,3.843,1.964,3.929,1.783
                l0.643-1.355C4.695,0.168,4.961,0,5.249,0c0.112,0,0.22,0.024,0.322,0.073c0.181,0.086,0.317,0.237,0.385,0.426
                C6.023,0.687,6.013,0.89,5.927,1.071L5.284,2.426C5.161,2.687,4.894,2.855,4.606,2.855z"></path>
        <path d="M2.678,6.921c-0.111,0-0.219-0.024-0.321-0.072C2.176,6.763,2.039,6.612,1.972,6.423C1.905,6.234,1.915,6.031,2.001,5.85
                l0.642-1.355c0.124-0.26,0.39-0.429,0.678-0.429c0.112,0,0.219,0.024,0.32,0.072c0.181,0.086,0.318,0.237,0.385,0.426
                c0.067,0.189,0.057,0.392-0.029,0.573L3.356,6.492C3.233,6.753,2.967,6.921,2.678,6.921z"></path>
        <path d="M0.749,10.987c-0.112,0-0.22-0.024-0.322-0.073c-0.181-0.086-0.318-0.237-0.385-0.426
                c-0.067-0.189-0.057-0.392,0.029-0.573L0.715,8.56C0.838,8.3,1.104,8.132,1.393,8.132c0.112,0,0.22,0.024,0.321,0.073
                C1.895,8.29,2.032,8.441,2.099,8.63C2.166,8.819,2.156,9.022,2.07,9.203l-0.643,1.355C1.303,10.819,1.037,10.987,0.749,10.987z"></path>
        <path d="M13.82,2.965c-0.35,0-0.657-0.248-0.732-0.59L12.767,0.91c-0.043-0.196-0.007-0.396,0.101-0.565
                c0.108-0.169,0.275-0.285,0.471-0.328C13.392,0.006,13.446,0,13.5,0c0.355,0,0.656,0.242,0.732,0.59l0.321,1.465
                c0.043,0.196,0.007,0.396-0.101,0.565c-0.108,0.169-0.275,0.285-0.471,0.328C13.927,2.959,13.873,2.965,13.82,2.965z"></path>
        <path d="M14.667,6.823c-0.349,0-0.657-0.248-0.732-0.589l-0.204-0.927c-0.089-0.404,0.167-0.805,0.571-0.894
                c0.053-0.012,0.108-0.018,0.162-0.018c0.355,0,0.656,0.242,0.732,0.589L15.4,5.912c0.089,0.404-0.167,0.805-0.571,0.894
                C14.775,6.817,14.721,6.823,14.667,6.823z"></path>
        <path d="M17.893,2.855c-0.288,0-0.554-0.168-0.678-0.429l-0.643-1.355c-0.177-0.374-0.017-0.822,0.356-0.999
                C17.029,0.024,17.137,0,17.249,0c0.288,0,0.554,0.168,0.678,0.428l0.643,1.355c0.177,0.374,0.017,0.822-0.356,0.999
                C18.112,2.831,18.005,2.855,17.893,2.855z"></path>
        <path d="M19.821,6.921c-0.288,0-0.554-0.168-0.678-0.429l-0.642-1.355c-0.177-0.374-0.017-0.822,0.356-0.999
                c0.102-0.048,0.21-0.072,0.321-0.072c0.288,0,0.554,0.168,0.677,0.428l0.642,1.355c0.177,0.374,0.017,0.822-0.356,0.999
                C20.04,6.897,19.932,6.921,19.821,6.921z"></path>
        <path d="M21.353,10.151c-0.288,0-0.554-0.168-0.678-0.429l-0.246-0.519c-0.177-0.374-0.017-0.822,0.356-0.999
                c0.101-0.048,0.209-0.072,0.321-0.072c0.289,0,0.555,0.168,0.678,0.429L22.03,9.08c0.177,0.374,0.017,0.822-0.356,0.999
                C21.572,10.127,21.464,10.151,21.353,10.151z"></path>
        </g>
        </svg>
    },
]