import React,{useState,useEffect} from 'react'
import Slider from 'react-slick'
import AllBlogs from '../../Component/AllBlogs'
import BreadCrumb from '../../Component/BreadCrumb'
import CallToAction from '../../Component/CallToAction'
import Footer from '../../Component/Footer'
import PropertiesSection from '../../Component/PropertiesSection'
import { hotelData } from '../../DataFiles/Hotel'
import './destination-page.scss'
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react"
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import map from '../../assets/images/map.png'
import { topDestinations } from '../../DataFiles/Hotel'
import { useParams } from 'react-router-dom'


const DestinationPage = (props) => {
    // const [form,setForm] = useState({
    //     showingInfoWindow: false,
    //     activeMarker: {},
    //     selectedPlace: {},
    //     address: '1600 Amphitheatre Parkway, Mountain View, CA',
    //   })
      useEffect(() => {
        window.scrollTo(0, 0);
      }, [])
      
      let {id} = useParams()
      let filterBlogData = topDestinations.filter((item)=>item.id == id)
      let filterBlog = filterBlogData && filterBlogData[0]
    // const onMarkerClick = (props, marker, e) => {
    //     setForm({...form,
    //       selectedPlace: props,
    //       activeMarker: marker,
    //       showingInfoWindow: true
    //     });
    //   };

    const settings2 = {
        dots: false,
        centerMode: true,
        centerPadding: '10%',
        arrows: true,
        speed: 500,
        slidesToShow: 1,
        autoplay: true,
        infinite: true,
        initialSlide:1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              infinite: false,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              centerPadding: '0%',
            },
          },
        ],
      }

  return (
    <div className='destination-page'>
        <BreadCrumb title={filterBlog.title} />

        <div className='carousel-images'>
        <Slider {...settings2}>
              {hotelData && hotelData.map((item, i) => {
                return (
                  <div className="tour-images" key={i}>
                    <img src={item.background} alt="user-tour" />
                  </div>
                )
              })}
        </Slider>
        </div>

        <div className='destination-content container'>
              <div className='content'>
                <h2>{filterBlog.title}</h2>
                <p>{filterBlog.desc}</p>
              </div>
              <section className='contact-us-google'>
                <img src={map} />
            {/* <Map
            google={props.google}
            zoom={14}
            style={mapStyles}
            initialCenter={{
                lat:39.1537975, lng: -84.4326192
            }}
            >
            <Marker onClick={onMarkerClick} position={{ lat:39.1537975, lng: -84.4326192}} />
            <InfoWindow marker={form.activeMarker} visible={form.showingInfoWindow}>
            <div>
                <div className='map-logo'>
                </div>
                <h3 className='placeName'>Location</h3>
                <p className='address-text'>{form.address}</p>
            </div>
            </InfoWindow>
            </Map> */}
             </section>
        </div>

        <PropertiesSection totalListing={4} heading={filterBlog.title} />
        
        <div className='blog-destination'>
         <AllBlogs />
        </div>

        <CallToAction />
        <Footer />
    </div>
  )
}

export default DestinationPage



function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div  onClick={onClick}  className='next-slider-icon'>
      <ArrowForwardIosRoundedIcon  className={className}
     />
      </div>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div  onClick={onClick} className='previous-slider-icon'>
      <ArrowBackIosNewRoundedIcon  className={className}
      />
      </div>
      
    );
  }