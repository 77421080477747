import React,{useState,useEffect} from 'react'
import BreadCrumb from '../../Component/BreadCrumb'
import { FaStar,FaFacebookF,FaTwitter,FaPinterestP,FaLinkedinIn,FaUserTie,FaCheck } from "react-icons/fa";
import { RiShareForwardLine,RiHeart3Line } from "react-icons/ri";
import Footer from '../../Component/Footer';
import Reviews from '../../Component/Reviews';
import { facilities } from '../../DataFiles/Hotel'
import { DateRangePicker } from 'react-dates';
import UserProfile from '../../Component/UserProfile';
import rentalMap from '../../assets/images/rentalmap.png'
import './car-rental.scss'
import Slider from 'react-slick';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import RentalCard from '../../Component/ListingCard/RentalCard';
import { hotelData,locations } from '../../DataFiles/Hotel'
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import { ClickAwayListener } from '@mui/material';
import EventBus from 'eventing-bus'

const CarRentalDetail = () => {
    const [showSocialIcon,setShowSocialIcon]= useState(false)
    const [startDate, setStartDate] = useState(null);  
    const [endDate, setEndDate] = useState(null);  
    const [focusedInput, setFocusedInput] = useState(null);
    const [showLocation,setShowLocation] = useState(false);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [])
    
    const [counsters,setCounsters] = useState({
        adultCounter:1,
        childrenCounter:1,
        roomCounter:1
      })

    const handleDatesChange = ({ startDate, endDate }) => {
        setStartDate(startDate);
         setEndDate(endDate);  
    };

    const handleLoginModal = ()=>{
      EventBus.publish('loginModal')
    }

    const settings = {
        customPaging: function(i) {
          return (
            <a className='slick-pagination'>
              <img src={url[i]}  />
            </a>
          );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
    };

    const settings2 = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };

  return (
    <div className='hotel-detail-container rentail-detail-container car-rental-detail'>
        <BreadCrumb title="Hotel Askorav" />
      
        <section className='hotel-detail-section hotel-container'>

            <section className='hotel-content-section'>
                <div className='hotel-reviews-box'>
           
            <div className='reviews-tours'>
            <div className='review-box'>
                   <FaStar />
                <span>5</span>
                <p className='review-no'>(3 Reviews)</p>
                <p className='location'>Los Angeles</p>
            </div>
         
            <div className='share-icons'>
            <ClickAwayListener onClickAway={()=>setShowSocialIcon(false)}>
                <span className='share-icon'>
                <RiShareForwardLine onClick={()=>setShowSocialIcon(!showSocialIcon)} />
                {showSocialIcon &&
                    <div className='social-share-icons'>
                    <span>
                    <FaFacebookF />
                    </span>
                    <span>
                    <FaTwitter />
                    </span>
                    <span>
                    <FaPinterestP />
                    </span>
                    <span>
                    <FaLinkedinIn />
                    </span>
                </div>
                }
                </span>
                </ClickAwayListener>

                <span onClick={handleLoginModal} className='like-icons'>
                <RiHeart3Line />
                </span>
            </div>
       
            </div>
            </div>
            
            <div className='car-rental-slider'>
            <Slider {...settings}>
                {url.map(item=>(
                    <div className='rental-img-box'>
                    <img src={item} />
                    </div>
                ))
                }
            </Slider>
            </div>

            {/* <div className='hotel-facility'> */}
                <div className='highlights-box'>
                    {Highlights.map(highlight=>(
                        <div>
                            <FaCheck className="true-svg" />
                            <p className='true-status'>{highlight}</p>
                        </div>
                    ))}
                </div>
            {/* </div> */}
           
            <div className='hotel-info'>
                <h2>About this hotel</h2>
                <p>Nestled in the heart of Torrance, Redac Gateway Hotel in Torrance is an ideal spot from which to discover Los Angeles (CA). From here, guests can enjoy easy access to all that the lively city has to offer. With its convenient location, the hotel offers easy access to the city’s must-see destinations.</p>
                <p>At Redac Gateway Hotel in Torrance, the excellent service and superior facilities make for an unforgettable stay. Guests of the hotel can enjoy on-site features like free Wi-Fi in all rooms, 24-hour front desk, facilities for disabled guests, express check-in/check-out, luggage storage.</p>
                <p>Experience high quality room facilities during your stay here. Some rooms include television LCD/plasma screen, carpeting, linens, mirror, sofa, provided to help guests recharge after a long day. Besides, the hotel’s host of recreational offerings ensures you have plenty to do during your stay. Redac Gateway Hotel in Torrance is an ideal place of stay for travelers seeking charm, comfort and convenience in Los Angeles (CA).</p>

            </div>

            <div className='hotel-facility' style={{borderBottom:"none",paddingBottom:"10px"}}>
                <h2>Car Features</h2>
                <div className='hotel-facilities-icons'>
                    {
                        facilities.slice(0,6).map((item)=>(
                            <div>
                            <svg version="1.1"  width="25" height="25" x="0" y="0" viewBox="0 0 24 24" ><g><g data-name="1"><path d="M20.364 8.17 15.83 3.636a5.529 5.529 0 0 0-4.167-1.612l-5 .239a4.6 4.6 0 0 0-4.4 4.4l-.239 5.006a5.537 5.537 0 0 0 1.612 4.161l4.534 4.534a5.527 5.527 0 0 0 7.809 0l4.385-4.385a5.526 5.526 0 0 0 0-7.809zM19.3 14.918 14.918 19.3a4.027 4.027 0 0 1-5.688 0L4.7 14.77a4.041 4.041 0 0 1-1.174-3.035l.239-5.006A3.108 3.108 0 0 1 6.73 3.761l5-.239A4.026 4.026 0 0 1 14.77 4.7l4.53 4.53a4.027 4.027 0 0 1 0 5.688zM9.5 6.25a3.25 3.25 0 1 0 3.25 3.25A3.254 3.254 0 0 0 9.5 6.25zm0 5a1.75 1.75 0 1 1 1.75-1.75 1.752 1.752 0 0 1-1.75 1.75z" fill="#000000" data-original="#000000" class=""></path><path fill="#fdaa29" d="m17.53 13.53-4 4a.75.75 0 0 1-1.06-1.06l4-4a.75.75 0 0 1 1.06 1.06z" data-original="#fdaa29" style={{display: "none"}}></path></g></g></svg>
                             <p>{item.title}</p>
                         </div>
                        ))
                    }
                    
                </div>
            </div>

            <div className='hotel-facility'>
                <h2>Brands</h2>
                <div className='hotel-facilities-icons'>
                    {
                        facilities.slice(0,3).map((item)=>(
                            <div>
                           <svg version="1.1"  width="25" height="25" x="0" y="0" viewBox="0 0 24 24" ><g><g data-name="1"><path d="M20.364 8.17 15.83 3.636a5.529 5.529 0 0 0-4.167-1.612l-5 .239a4.6 4.6 0 0 0-4.4 4.4l-.239 5.006a5.537 5.537 0 0 0 1.612 4.161l4.534 4.534a5.527 5.527 0 0 0 7.809 0l4.385-4.385a5.526 5.526 0 0 0 0-7.809zM19.3 14.918 14.918 19.3a4.027 4.027 0 0 1-5.688 0L4.7 14.77a4.041 4.041 0 0 1-1.174-3.035l.239-5.006A3.108 3.108 0 0 1 6.73 3.761l5-.239A4.026 4.026 0 0 1 14.77 4.7l4.53 4.53a4.027 4.027 0 0 1 0 5.688zM9.5 6.25a3.25 3.25 0 1 0 3.25 3.25A3.254 3.254 0 0 0 9.5 6.25zm0 5a1.75 1.75 0 1 1 1.75-1.75 1.752 1.752 0 0 1-1.75 1.75z" fill="#000000" data-original="#000000" class=""></path><path fill="#fdaa29" d="m17.53 13.53-4 4a.75.75 0 0 1-1.06-1.06l4-4a.75.75 0 0 1 1.06 1.06z" data-original="#fdaa29" style={{display: "none"}}></path></g></g></svg>
                             <p>{item.title}</p>
                         </div>
                        ))
                    }
                    
                </div>
            </div>


            <div className='rental-map-container'>
                <div className='rental-heading'>
                <h2>Car's Location</h2>
                <span>
                </span>
                </div>
                <img src={rentalMap} />
            </div>

            <div>
                <Reviews type="CarRental" />
            </div>

        </section>

        <section className='hotel-right-section'>
        {/* <div className='right-section-stick'> */}
            <div className='booking-section'>
                <div>
                <div className="hotel-price">
                    <p>From <span>€136.00 </span>/night</p>
                    <div>
                    <FaStar />  <p><span>5</span> (3 reviews)</p>
                    </div>
                </div>

                <div className='hotel-date-selecter'>
                <div className='guest-text-box'>
                <div className='location-bar'>
        <ClickAwayListener onClickAway={()=>setShowLocation(false)}>
        <div onClick={()=>setShowLocation(!showLocation)} className='heading'>
        <p className='location-heading-top'>Location</p>
        <p className='inof-text'>United States</p>
        </div>
        </ClickAwayListener>
        
        {showLocation &&
          <div className='location-dropdown'>
          <p className='location-heading'>Popular Location</p>
          <div>
            <span>United state</span>
            {
              locations.map((location,index)=>(
                <div className='locations'>
                  <FmdGoodOutlinedIcon />
                  <p>{location}</p>
                </div>
              ))
            }
          </div>
        </div>}
      </div>
                    
            </div>
                <div className='date-picker'>
                  <p className='pickup-text'>Pickup</p>
                  <p className='dropoff-text'>Drop-off</p>
                    <DateRangePicker        
                    startDate={startDate} 
                    placeholderText={'Please select a date'}        
                    startDateId="date_picker_start_date_id"
                    endDate={endDate}   
                    startDatePlaceholderText="mm/dd/yyyy"    
                    endDatePlaceholderText="mm/dd/yyyy"
                    startDateTitleText="mm/dd/yyyy"
                    startDateAriaLabel="mm/dd/yyyy"
                    endDateAriaLabel="mm/dd/yyyy"
                    endDateTitleText="mm/dd/yyyy"
                    endDateId="date_picker_end_date_id"        
                    onDatesChange={handleDatesChange}       
                    focusedInput={focusedInput}        
                    onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                    // isOutsideRange={isOutsideRange}
                    />
                </div>

                </div>
                <div className='total-price'>
                    <p>Total</p>
                    <p>€150.00</p>
                </div>
                <div className='availabilty-check'>
                <button className='primary-btn '>Check Availability</button>
                </div>
                </div>
            </div>

            

            <div>
                <UserProfile />
            </div>

        </section>

        </section>

        <div className='recommended-container hotel-container'>
        <h2 className='recommend-heading'>Explore More Options</h2>
        <Slider  {...settings2} className='recommended-card'>
            {
              hotelData.map((hotel)=>(
                <RentalCard hotel={hotel} type="Car" />
              ))
            }
        </Slider>
        </div>

        <Footer />
    </div>
  )
}

export default CarRentalDetail



let url = ["https://images.pexels.com/photos/1719648/pexels-photo-1719648.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2?number=1","https://images.pexels.com/photos/3802510/pexels-photo-3802510.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2?number=2","https://images.pexels.com/photos/733745/pexels-photo-733745.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2?number=3","https://images.pexels.com/photos/116675/pexels-photo-116675.jpeg?auto=compress&cs=tinysrgb&w=800?number=4","https://images.pexels.com/photos/1719648/pexels-photo-1719648.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2?number=1"]

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className='next-slider-icon'>
      <ArrowForwardIosRoundedIcon  className={className}
      onClick={onClick} />
      </div>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className='previous-slider-icon'>
      <ArrowBackIosNewRoundedIcon  className={className}
      onClick={onClick} />
      </div>
      
    );
  }

let Highlights = ["Free Cancellation","Pay at Pickup","Unlimited Mileage","Shuttle to Car"]
