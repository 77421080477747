import React,{useState} from 'react'
import { FaStar,FaRegThumbsUp } from "react-icons/fa";
import './reviews.scss'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { TextField } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Rating from '@mui/material/Rating';
import { styled } from '@mui/material/styles';
import Pagination from '../Pagination';

let reviewsIcon = [
    {
        title:"Cleanliness",
        progress:30,
        review:"2",
    },
    {
        title:"Accuracy",
        progress:100,
        review:"3",
    },
    {
        title:"Communication",
        progress:100,
        review:"5",
    },
    {
        title:"Location",
        progress:100,
        review:"5",
    },
    {
        title:"Check-in",
        progress:100,
        review:"3",
    },
    {
        title:"Value",
        progress:100,
        review:"3",
    },
]

let tourReviewIcon = [
    {
        title:"Cleanliness",
        progress:30,
        review:"2",
    },
    {
        title:"Accuracy",
        progress:100,
        review:"3",
    },
    {
        title:"Communication",
        progress:100,
        review:"5",
    },
    {
        title:"Location",
        progress:100,
        review:"5",
    },
    {
        title:"Check-in",
        progress:100,
        review:"3",
    },
]

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: '#FFB21D',
    },
    '& .MuiRating-iconHover': {
      color: '#FFB21D',
    },
  });

let reviews = [
    {
        user:"Customer",
        date:"3/2/2020",
        avatar:<AccountCircleIcon />,
        stars:2,
        likes:2,
        desc:"Spacious room with wonderful view!” -nice and cozy room -wonderful view -calm and peaceful -very nice owner. She welcome us in our car. They even gave us a bottle of coffee! -you will need a car to get here. -i love this place so much.        "
    },
    {
        user:"Customer",
        date:"3/2/2020",
        avatar:<AccountCircleIcon />,
        stars:2,
        likes:2,
        desc:"Spacious room with wonderful view!” -nice and cozy room -wonderful view -calm and peaceful -very nice owner. She welcome us in our car. They even gave us a bottle of coffee! -you will need a car to get here. -i love this place so much.        "
    },
    {
        user:"Customer",
        date:"3/2/2020",
        avatar:<AccountCircleIcon />,
        stars:2,
        likes:2,
        desc:"Spacious room with wonderful view!” -nice and cozy room -wonderful view -calm and peaceful -very nice owner. She welcome us in our car. They even gave us a bottle of coffee! -you will need a car to get here. -i love this place so much.        "
    },
    {
        user:"Customer",
        date:"3/2/2020",
        avatar:<AccountCircleIcon />,
        stars:2,
        likes:2,
        desc:"Spacious room with wonderful view!” -nice and cozy room -wonderful view -calm and peaceful -very nice owner. She welcome us in our car. They even gave us a bottle of coffee! -you will need a car to get here. -i love this place so much.        "
    },
    {
        user:"Customer",
        date:"3/2/2020",
        avatar:<AccountCircleIcon />,
        stars:2,
        likes:2,
        desc:"My favorite stay in !! The room is big, clean and so tidy. The cooking area and lotion provided was the favorite parts! Will come again if I visit."
    },
    {
        user:"Customer",
        date:"3/2/2020",
        avatar:<AccountCircleIcon />,
        stars:2,
        likes:2,
        desc:"My favorite stay in !! The room is big, clean and so tidy. The cooking area and lotion provided was the favorite parts! Will come again if I visit."
    },
]
const Reviews = ({type}) => {
    const [showForm,setShowForm] = useState(false)
    let reviewsData =  type !=="Tour"?  reviewsIcon:tourReviewIcon
      //custom pagination
      const [currentPage, setCurrentPage] = useState(1);
      const totalPages = Math.ceil(reviews.length / 3);
      const startIndex = (currentPage - 1) * 3;
      const endIndex = startIndex + 3;
      const currentData = reviews.slice(startIndex, endIndex);
      console.log("currentData",currentData)
      const handlePageChange = pageNumber => {
        setCurrentPage(pageNumber);
      };
  return (
    <div className='reviews-container'>
        <h2 className='review-heading'>Reviews</h2>
       {
       type !== "Tour"?
        <div className='reviews-total-box'>
            <div className='review-stars'>
                <FaStar />
                <p>5/5 Excellent</p>
                <span>(3 reviews)</span>
            </div>
        <div className='reviews-icons-box'>
        {
            reviewsData.map((review) =>(
                <div className='reviews-progress'>
                    <p>{review.title}</p>
                    <span className='progress-bar'>
                    </span>
                    <p>5/{review.review}</p>
                </div>
            ))
        }
        </div>
        </div>:

        <div className='tour-review-total-box'>
            <div className='review-stars'>
                <div className='star-tour'>
                <FaStar />
                <p>5/5 </p>
                </div>
                <h2>Excellent</h2>
                <span>(3 reviews)</span>
            </div>
        <div className='reviews-icons-box'>
        {
            reviewsData.map((review) =>(
                <div className='reviews-progress'>
                    <p>{review.title}</p>
                    <span className='progress-bar'>
                    </span>
                    <p>5/{review.review}</p>
                </div>
            ))
        }
        </div>
        </div>
        
        }

        <p className='total-review-desc'>3 reviews on this Hotel - Showing 1 to 3</p>

        <div className='reviews-desc-box'>
        {
          currentData.map((items,index)=>(
                <div key={index} className='reviews-desc'>
                    <div className='review-header'>
                        <div className='review-avatar'>
                            <span>{items.avatar}</span>
                            <div>
                            <p>{items.user}</p>
                            <p className='date'>{items.date}</p>
                            </div>
                        </div>
                        <div className='like-icon'>
                            <FaRegThumbsUp /><p>{items.likes}</p>
                        </div>
                    </div>
                <div className='stars-icons'>
                   {(type == "Hotel" || type == "Tour") ?
                    <>
                    <FaStar /><FaStar /><FaStar /><FaStar /><FaStar /><FaStar />
                    </>:
                    <p className='review-rating-text'>5.0</p>
                    }
                    <p>{items.desc}</p>
                </div>
                </div>
            ))
        }
        </div>

       {(type == "Rental" || type == "CarRental") &&
        <div className='review-pagination'>
        <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            type="review"
          />
        </div>
        }

        <div>
            <button onClick={()=>setShowForm(!showForm)} className='primary-btn'>Write a Review {!showForm ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}</button>
        </div>

      { showForm &&
       <div className='reviews-form'>
            <h2>Leave a reply</h2>
            <p className='required-text'>Your email address will not be published. Required fields are marked *</p>
            <div className='review-fields'>
            <TextField id="outlined-basic" label="Name*" variant="outlined" />
            <TextField id="outlined-basic" label="Email*" variant="outlined" />
            </div>
            <div className='title-field'>
            <TextField id="outlined-basic" label="Title" variant="outlined" />
            </div>
            <div className='reviews-stars'>
                <div>
                    <p>Cleanliness</p>
                    <div>
                    <StyledRating
                        name="customized-color"
                        defaultValue={5}
                        icon={<FaStar />}
                        emptyIcon={<FaStar className='active-icon' />}
                    />
                    </div>
                </div>
                <div>
                    <p>Accuracy</p>
                    <div>
                    <StyledRating
                        name="customized-color"
                        defaultValue={5}
                        icon={<FaStar />}
                        emptyIcon={<FaStar className='active-icon' />}
                    />
                    </div>
                </div>
                <div>
                    <p>Communication</p>
                    <div>
                    <StyledRating
                        name="customized-color"
                        defaultValue={5}
                        icon={<FaStar />}
                        emptyIcon={<FaStar className='active-icon' />}
                    />
                    </div>
                </div>
                <div>
                    <p>Location</p>
                    <div>
                    <StyledRating
                        name="customized-color"
                        defaultValue={5}
                        icon={<FaStar />}
                        emptyIcon={<FaStar className='active-icon' />}
                    />
                    </div>
                </div>
                <div>
                    <p>Check-in</p>
                    <div>
                    <StyledRating
                        name="customized-color"
                        defaultValue={5}
                        icon={<FaStar />}
                        emptyIcon={<FaStar className='active-icon' />}
                    />
                    </div>
                </div>
                <div>
                    <p>Value</p>
                    <div>
                    <StyledRating
                        name="customized-color"
                        defaultValue={5}
                        icon={<FaStar />}
                        emptyIcon={<FaStar className='active-icon' />}
                    />
                    </div>
                </div>
            </div>
            <div className='content-field'>
            <TextField id="outlined-basic" multiline rows={4} label="Content" variant="outlined" />
            </div>
            <button className='primary-btn'>POST COMMENT</button>
        </div>
        }
    </div>
  )
}

export default Reviews

