import React from 'react'
import './tour-card.scss'
import StarRateIcon from '@mui/icons-material/StarRate';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { FaStar,FaRegHeart } from "react-icons/fa";
import { Link } from 'react-router-dom';

const TourCard = ({hotel}) => {
  return (
    <div className='property-card tour-card'>
     <div className='card-image-box'>
       <img className='card-background' src = {hotel.background}  />
      
     { hotel.offer && <p className='hotels-sale'>{hotel.offer}</p>}
       <FaRegHeart className='fav-icon' />
       </div>

        <div className='card-details'>
        <img className='avatar-img' src={hotel.avatar} />

       <div>
            <p className='location'><LocationOnOutlinedIcon /> {hotel.location}</p>
            <Link to="/tour-detail"><h3>{hotel.title}</h3></Link>
       </div>

       <div className='review-box'>
            <div className='review-star'>
            <FaStar style={{color:"#FFB21D",fontSize:"15px",marginRight:"5px",marginLeft:"0px"}} />  5
            </div>
            <p className='review-text'>(3 Reviews)</p>
       </div>

       <div className='price-tag'>
        <p>From :<span> {hotel.price} </span> / {hotel.time}</p>
        <p className='clock-time'><AccessTimeOutlinedIcon /> 5 days</p>
       </div>
       </div>

    </div>
  )
}

export default TourCard
