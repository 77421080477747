import React,{useState} from 'react'
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { locations } from '../../../DataFiles/Hotel';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import './tour-search.scss'

const CarTransferSearch = ({
  searchType
}) => {
  const [pickUp,setPickUp] = useState(false);
  const [dropOff,setDropOff] = useState(false); 

  return (
    <div className='hotel-search-bar car-trnasfer-search'>
      <div className='location-bar'>
        <div onClick={()=>setPickUp(!pickUp)} className='heading'>
        <FmdGoodOutlinedIcon />
        <p>Pick-up</p>
        </div>
        <p className='inof-text'>Pick-up from</p>
        {pickUp &&
          <div className='location-dropdown'>
          <p className='location-heading'>Popular Location</p>
          <div>
            <span>United state</span>
            {
              locations.map((location,index)=>(
                <div className='locations'>
                  <FmdGoodOutlinedIcon />
                  <p>{location}</p>
                </div>
              ))
            }
          </div>
        </div>}
      </div>
      

      <div className='location-bar'>
        <div onClick={()=>setDropOff(!dropOff)} className='heading'>
        <FmdGoodOutlinedIcon />
        <p>Drop-off</p>
        </div>
        <p className='inof-text'>Drop-off From</p>
        {dropOff &&
          <div className='location-dropdown'>
          <p className='location-heading'>Popular Location</p>
          <div>
            <span>United state</span>
            {
              locations.map((location,index)=>(
                <div className='locations'>
                  <FmdGoodOutlinedIcon />
                  <p>{location}</p>
                </div>
              ))
            }
          </div>
        </div>}
      </div>

        <button className='primary-btn'>
          Search
        </button>
    </div>
  )
}

export default CarTransferSearch