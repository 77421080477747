import React from 'react';
import './pagination.scss'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const Pagination = ({ currentPage, totalPages, onPageChange,type }) => {
  const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  const maxVisiblePages = 3;
  let displayedPages = [];
  if (totalPages <= maxVisiblePages) {
    displayedPages = pageNumbers;
  } else {
    const middleIndex = Math.floor(maxVisiblePages / 2);
    const minDisplayIndex = Math.max(currentPage - middleIndex, 0);
    const maxDisplayIndex = Math.min(minDisplayIndex + maxVisiblePages - 1, totalPages - 1);

    if (minDisplayIndex > 0) {
      displayedPages.push(1);
      if (minDisplayIndex > 1) {
        displayedPages.push('...');
      }
    }

    for (let i = minDisplayIndex; i <= maxDisplayIndex; i++) {
      displayedPages.push(i + 1);
    }

    if (maxDisplayIndex < totalPages - 1) {
      if (maxDisplayIndex < totalPages - 3) {
        displayedPages.push('...');
      }
      displayedPages.push(totalPages);
    }
  }

  return (
    <nav className='custom-pagination'>
       
      <ul className='pagination'>
        {type == "review" &&
          <li className='pagination-btn'>
          <span className={currentPage == totalPages ? ' disabled-span' : 'active-span'} onClick={currentPage !== 1 ? () => onPageChange(currentPage - 1):null}>
          <KeyboardArrowLeftIcon />
        </span>

      </li>
      }
      
        {
          displayedPages.length > 0 ?
          displayedPages.map(pageNumber => (
            <li key={pageNumber}>
              <button onClick={() => onPageChange(pageNumber)} className={pageNumber == currentPage ? ' active' : 'not-active'}>
                {pageNumber}
              </button>
            </li>
          )):
          0
        }
        <li className='pagination-btn'>
          <span className={currentPage == totalPages ? ' disabled-span' : 'active-span'} onClick={currentPage !== totalPages ? () => onPageChange(currentPage + 1):null }>
          <KeyboardArrowRightIcon />
        </span>

        </li>
      </ul>
    </nav>
  );
};

export default Pagination;