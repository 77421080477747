import React from 'react'
import './user-profile.scss'

const UserProfile = () => {
  return (
    <div className='user-profile-box'>
        <img src="https://images.pexels.com/photos/1310522/pexels-photo-1310522.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />
        <p className='name'>modmix</p>
        <p className='date'>Member Since 2022</p>
    </div>
  )
}

export default UserProfile