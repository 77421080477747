import React,{ useState,useEffect } from 'react'
import Header from '../../Component/Header'
import banner1 from '../../assets/images/promotion1.png'
import banner2 from '../../assets/images/promotion2.png'
import './landing.scss'
import { hotelData,topDestinations } from '../../DataFiles/Hotel'
import CallToAction from '../../Component/CallToAction'
import Footer from '../../Component/Footer'
import HotelSearchBar from '../../Component/SearchBars/HotelSearchBar'
import CarTransferSearch from '../../Component/SearchBars/CarTransferSearch'
import AllBlogs from '../../Component/AllBlogs'
import PropertiesSection from '../../Component/PropertiesSection'
import { Link } from 'react-router-dom'

const LandingPage = () => {
  const [tabs,setTabs] = useState("Hotel")
  const [activeSearch,setActiveSearch] = useState("Hotel")

  return (
    <div className='landing-page'>

      <section className='hero-image-banner'>
        <Header />

        <div className='hero-text'>
          <h2>Let the journey begin</h2>
          <p>Get the best prices on 2,000,000+ properties, worldwide</p>
        </div>

        <div className='search-bar-tabs'>
          <p className={activeSearch == "Hotel"?"active-search":"not-active-search"} onClick={()=>setActiveSearch("Hotel")}>Hotel</p>
          <p className={activeSearch == "Tours"?"active-search":"not-active-search"} onClick={()=>setActiveSearch("Tours")}>Tours</p>
          <p className={activeSearch == "Activity"?"active-search":"not-active-search"} onClick={()=>setActiveSearch("Activity")}>Activity</p>
          <p className={activeSearch == "Rental"?"active-search":"not-active-search"} onClick={()=>setActiveSearch("Rental")}>Rental</p>
          <p className={activeSearch == "CarRental"?"active-search":"not-active-search"} onClick={()=>setActiveSearch("CarRental")}>Car Rental</p>
          <p className={activeSearch == "CarTransfer"?"active-search":"not-active-search"} onClick={()=>setActiveSearch("CarTransfer")}>Car Transfer</p>
        </div>
        {activeSearch == "Hotel" ?
          <HotelSearchBar searchType="hotel" />:
          activeSearch == "Tours"?
          <HotelSearchBar searchType="Tours" />:
          activeSearch == "Activity"?
          <HotelSearchBar searchType="Activity" />:
          activeSearch == "Rental"?
          <HotelSearchBar searchType="hotelRental" />:
          activeSearch == "CarRental"?
          <HotelSearchBar searchType="carRental" />:
          <CarTransferSearch />
        }
      </section>

        <section className='sale-banners container'>
            <div className='banner'>
                <img src={banner1} />
            </div>
            <div className='banner'>
                <img src={banner2} />
            </div>
        </section>

        <section className='top-destinations container'>
          <h2>Top Destination</h2>
          
            <div className='destinations-container'>
              {
                topDestinations.map((destination) =>(
                  <div className='single-destination-card'>
                  <img className='background-image' src={destination.img} />
                   <div className='destination-content'>
                   <Link to={`/destination/${destination.id}`}>
                   <p className='banner-heading'>{destination.title}</p>
                   </Link> 
                      <div className='destination-titles'>
                        <li>{destination.Activity} Activities</li>
                        <li>{destination.car} Cars</li>
                        <li>{destination.hotel} Hotels</li>
                        <li>{destination.rental} Rental</li>
                        <li>{destination.tours} Tours</li>
                      </div>
                    </div>
                  {/* </div> */}
                  </div>
                ))
              }
            </div>
        </section>

        
        <PropertiesSection showTabs={true} showPagination={true}  totalListing={8} heading="Recommended for you" />

        <AllBlogs />

          <CallToAction />

        <footer>
           <Footer />
        </footer>

    </div>
  )
}

export default LandingPage
