import React from 'react'
import { blogData } from '../../DataFiles/BlogData'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import Slider from 'react-slick';
import BlogCard from '../BlogCard';
import './all-blog.scss'

const AllBlogs = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
  return (
         <section className='container blog-section'>
          <div >
            <h2 className='blog-heading'>Stories, tips, and guides</h2>
          </div>
          <Slider {...settings}>
           {blogData.map((blog)=>(
            <BlogCard blog={blog} />
           ))
           }
          </Slider>
        </section>
  )
}

export default AllBlogs

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className='next-slider-icon'>
      <ArrowForwardIosRoundedIcon  className={className}
      onClick={onClick} />
      </div>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className='previous-slider-icon'>
      <ArrowBackIosNewRoundedIcon  className={className}
      onClick={onClick} />
      </div>
      
    );
  }