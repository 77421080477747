import React from 'react'
import Header from '../Header'
import './breadcrumb.scss'

const BreadCrumb = ({title}) => {
    let background = "https://images.pexels.com/photos/105933/pexels-photo-105933.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
  return (
    <div className='breadcrumb-container'>
        <div className='breadcrumb-image' style={{backgroundImage: "linear-gradient(rgb(0 0 0 / 17%), rgb(0 0 0 / 67%)),url(" + background + ")"}}>
            <Header />
    <div className='container'>
        <h1>{title}</h1>
        <ul className='breadcrumb-info'>
            <li>Home</li>
            <li>United State</li>
            <li>{title}</li>
        </ul>
        </div>
        </div>
    </div>
  )
}

export default BreadCrumb