import React from 'react'
import './image-carousel.scss'
import { FaPlay } from "react-icons/fa";
import { MdOutlineDashboard,MdClose } from "react-icons/md";
import { Modal } from '@mui/material';
import ReactPlayer from "react-player"
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1100,
    backgroundColor:"white",
    boxShadow: 24,
    height:600,
};

const ImageCarousel = ({type}) => {
    const [open, setOpen] = React.useState(false);
    const [openGallery, setOpenGallery] = React.useState(false);
    const [photoIndex, setPhotoIndex] = React.useState(0);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleCarousel=(number)=>{
        console.log(number)
        setPhotoIndex(number)
        setOpenGallery(true)
    }
  return (
    <div className={type == "hotel" ?'': ' -iamge-container'}>
    <div className='image-carousel' >
        <div className='images-section'>
            <div className='big-img'>
            <img onClick={()=>handleCarousel(0)} src={images[0]} />
            </div>
            <div className='sub-imgs'>
                    <img onClick={()=>handleCarousel(1)} src={images[1]} />
                    <img onClick={()=>handleCarousel(2)} src={images[2]} />
                    <img onClick={()=>handleCarousel(3)} src={images[3]} />
                    <div className='last-image'>
                    <img onClick={()=>handleCarousel(4)} src={images[4]} />
                    <div className='play-icons'>
                        <div className='video-icon'>
                        <FaPlay onClick={handleOpen}   />
                        </div>
                        <div onClick={()=>setOpenGallery(true)} className='all-photos'>
                            <MdOutlineDashboard />
                            <p>All Photo</p>
                        </div>
                    </div>
                    </div>
            </div>
        </div>

       {openGallery &&
       <div className='slider-carousel'>
        <Lightbox
            mainSrc={images[photoIndex]}
            enableZoom={false}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => setOpenGallery(!openGallery)}
            onMovePrevRequest={() =>
              setPhotoIndex(
                (photoIndex + images.length - 1) % images.length,
              )
            }
            onMoveNextRequest={() =>
                setPhotoIndex(
                (photoIndex + 1) % images.length,
              )
            }
          />
          </div>}

        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div style={style}>
        <div className='video-modal'>
            <MdClose onClick={handleClose} className='modal-close-btn' />
            <ReactPlayer
                className = "video-paly" 
                url = "https://www.youtube.com/watch?v=4jnzf1yj48M" 
                width="1100px"
                height="600px"
                /> 
        </div>
        </div>
      </Modal>
    </div>
    </div>
  )
}

export default ImageCarousel


let images = ["https://images.pexels.com/photos/3988297/pexels-photo-3988297.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
              "https://images.pexels.com/photos/15621208/pexels-photo-15621208/free-photo-of-room-with-armchairs-and-tv.jpeg?auto=compress&cs=tinysrgb&w=800",
              "https://images.pexels.com/photos/3201921/pexels-photo-3201921.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
              "https://images.pexels.com/photos/3209049/pexels-photo-3209049.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
            "https://images.pexels.com/photos/2506990/pexels-photo-2506990.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
]   