import React,{ useState,useEffect } from 'react'
import BreadCrumb from '../../Component/BreadCrumb'
import ImageCarousel from '../../Component/ImageCarousel'
import { FaStar,FaFacebookF,FaTwitter,FaPinterestP,FaLinkedinIn,FaUserTie,FaCheck,FaRegClock } from "react-icons/fa";
import { RiShareForwardLine,RiHeart3Line,RiQuestionnaireLine } from "react-icons/ri";
import Footer from '../../Component/Footer';
import Reviews from '../../Component/Reviews';
import { facilities } from '../../DataFiles/Hotel'
import { SingleDatePicker } from 'react-dates';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import UserProfile from '../../Component/UserProfile';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import ChildCareOutlinedIcon from '@mui/icons-material/ChildCareOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import rentalMap from '../../assets/images/rentalmap.png'
import './tour-detail.scss'
import CloseIcon from '@mui/icons-material/Close';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Slider from 'react-slick';
import { hotelData } from '../../DataFiles/Hotel'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import TourCard from '../../Component/ListingCard/TourCard';
import { ClickAwayListener } from '@mui/material';
import EventBus from 'eventing-bus'

const TourDetail = () => {
    const [showSocialIcon,setShowSocialIcon]= useState(false)
    const [startDate, setStartDate] = useState(null);  
    const [endDate, setEndDate] = useState(null);  
    const [focusedInput, setFocusedInput] = useState(null);
    const [expanded,setExpanded] = useState(true)
    const [counsters,setCounsters] = useState({
        adultCounter:1,
        childrenCounter:1,
        roomCounter:1
    })

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const handleGuestCounter = (type,calc) =>{
        let {roomCounter,adultCounter,childrenCounter} = counsters
        if(type == "room"){
          if(calc == "add"){
          setCounsters({...counsters,roomCounter:roomCounter+1})
          }else{
            setCounsters({...counsters,roomCounter:roomCounter-1})
          }
        }else if(type == "adult"){
          if(calc == "add"){
            setCounsters({ ...counsters, adultCounter:adultCounter+1 })
          }else{
            setCounsters({ ...counsters, adultCounter:adultCounter-1 })
          }
         
        }else {
          if(calc == "add"){
            setCounsters({ ...counsters, childrenCounter:childrenCounter + 1})
          }else{
            setCounsters({ ...counsters, childrenCounter:childrenCounter - 1})
          }
      
        }
    }

    const handleDatesChange = ({ startDate, endDate }) => {
        setStartDate(startDate);
         setEndDate(endDate);  
    };

    const handleFun=()=>{
        setExpanded(!expanded)
    }

    const onFocusChange=({ focused }) =>{
        setFocusedInput(focused);
    }

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    };

    const handleLoginModal = ()=>{
        EventBus.publish('loginModal')
    }
  return (
    <div className='hotel-detail-container rentail-detail-container tour-details-container'>
        <BreadCrumb title="Hotel Askorav" />
        <div className='hotel-reviews-box container'>
            <div className='reviews-tours '>
            <div style={{width:"18%"}} className='review-box'>
                    <FaStar />
                <span>5</span>
                <p className='review-no'>(3 Reviews)</p>
                <p className='location'>Los Angeles</p>
            </div>
           
            <div className='share-icons'>
            <ClickAwayListener onClickAway={()=>setShowSocialIcon(false)}>
                <span className='share-icon'>
                <RiShareForwardLine onClick={()=>setShowSocialIcon(!showSocialIcon)} />
                {showSocialIcon &&
                    <div className='social-share-icons'>
                    <span>
                    <FaFacebookF />
                    </span>
                    <span>
                    <FaTwitter />
                    </span>
                    <span>
                    <FaPinterestP />
                    </span>
                    <span>
                    <FaLinkedinIn />
                    </span>
                </div>
                }
                </span>
                </ClickAwayListener>

                <span onClick={handleLoginModal} className='like-icons'>
                <RiHeart3Line />
                </span>
            </div>
          
            </div>
        </div>

        <ImageCarousel type='tour' />

        <section className='hotel-detail-section hotel-container'>
            <section className='hotel-content-section'>
           
            <div className='icons-div'>
                <div>
                    <FaRegClock />
                    <div className='tours-timing'>
                        <h4>Duration</h4>
                        <p>8 hour</p>
                    </div>
                </div>
                <div>
                    <BedOutlinedIcon />
                    <div className='tours-timing'>
                        <h4>Tour Type</h4>
                        <p>Daily Tour</p>
                    </div>
                </div>
                
                <div>
                    <FaUserTie />
                    <div className='tours-timing'>
                        <h4>Group Size</h4>
                        <p>10 people</p>
                    </div>
                </div>
                <div>
                    <ChildCareOutlinedIcon />
                    <div className='tours-timing'>
                        <h4>Languages</h4>
                        <p>Espanol, Francais</p>
                    </div>
                </div>
            </div>

            <div className='hotel-info'>
                <h2>About this hotel</h2>
                <p>Nestled in the heart of Torrance, Redac Gateway Hotel in Torrance is an ideal spot from which to discover Los Angeles (CA). From here, guests can enjoy easy access to all that the lively city has to offer. With its convenient location, the hotel offers easy access to the city’s must-see destinations.</p>
                <p>At Redac Gateway Hotel in Torrance, the excellent service and superior facilities make for an unforgettable stay. Guests of the hotel can enjoy on-site features like free Wi-Fi in all rooms, 24-hour front desk, facilities for disabled guests, express check-in/check-out, luggage storage.</p>
                <p>Experience high quality room facilities during your stay here. Some rooms include television LCD/plasma screen, carpeting, linens, mirror, sofa, provided to help guests recharge after a long day. Besides, the hotel’s host of recreational offerings ensures you have plenty to do during your stay. Redac Gateway Hotel in Torrance is an ideal place of stay for travelers seeking charm, comfort and convenience in Los Angeles (CA).</p>

            </div>

            <div className='hotel-facility'>
                <h2>Highlights</h2>
                <div className='highlights-box'>
                    {Highlights.map(highlight=>(
                        <div>
                            <FaCheck className="true-svg" />
                            <p className='true-status'>{highlight}</p>
                        </div>
                    ))}
                </div>
            </div>

            <div className='hotel-facility'>
                <h2>Included/Excluded</h2>
                <div className='highlights-box Included-benefit'>
                    {hotelBenefit.map(highlight=>(
                        <div>
                            {highlight.status=="no"?
                                <CloseIcon className="false-svg" />:
                                <FaCheck className="true-svg" />
                            }
                            
                            <p >{highlight.title}</p>
                        </div>
                    ))}
                </div>
            </div>

            <div className='hotel-facility'>
                <h2>Itenary</h2>
            <div className='accordians-box'>
            <Accordion onClick={handleFun}>
                <AccordionSummary
                expandIcon={expanded? <AddOutlinedIcon className='accrodian-open-icon' />:<RemoveOutlinedIcon className='accrodian-close-icon'  />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <div className='accordian-heading'>
                    <p>08:00pm</p>
                    <h4>See The Beasutiful mountain</h4>
                </div>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                In 1972, the Urraco, which had experienced several initial slowdowns, was finally put into production. Almost inevitably, the S version also arrived in October of that year. In this case, the goal was not to enhance the car's performance but to improve its overall quality, which had been neglected in the haste to start production.
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion onClick={handleFun}>
                <AccordionSummary
                expandIcon={expanded? <AddOutlinedIcon className='accrodian-open-icon' />:<RemoveOutlinedIcon className='accrodian-close-icon'  />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <div className='accordian-heading'>
                    <p>08:00pm</p>
                    <h4>See The Beasutiful mountain</h4>
                </div>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                In 1972, the Urraco, which had experienced several initial slowdowns, was finally put into production. Almost inevitably, the S version also arrived in October of that year. In this case, the goal was not to enhance the car's performance but to improve its overall quality, which had been neglected in the haste to start production.
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion onClick={handleFun}>
                <AccordionSummary
               expandIcon={expanded? <AddOutlinedIcon className='accrodian-open-icon' />:<RemoveOutlinedIcon className='accrodian-close-icon'  />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <div className='accordian-heading'>
                    <p>08:00pm</p>
                    <h4>See The Beasutiful mountain</h4>
                </div>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                In 1972, the Urraco, which had experienced several initial slowdowns, was finally put into production. Almost inevitably, the S version also arrived in October of that year. In this case, the goal was not to enhance the car's performance but to improve its overall quality, which had been neglected in the haste to start production.
                </Typography>
                </AccordionDetails>
            </Accordion>
            </div>
            </div>

            <div className='hotel-facility' style={{paddingBottom:"0px",borderBottom:"none"}}>
                <h2>Durations</h2>
                <div className='hotel-facilities-icons'>
                    {
                        facilities.slice(0,3).map((item)=>(
                            <div>
                             <svg version="1.1"  width="25" height="25" x="0" y="0" viewBox="0 0 24 24" ><g><g data-name="1"><path d="M20.364 8.17 15.83 3.636a5.529 5.529 0 0 0-4.167-1.612l-5 .239a4.6 4.6 0 0 0-4.4 4.4l-.239 5.006a5.537 5.537 0 0 0 1.612 4.161l4.534 4.534a5.527 5.527 0 0 0 7.809 0l4.385-4.385a5.526 5.526 0 0 0 0-7.809zM19.3 14.918 14.918 19.3a4.027 4.027 0 0 1-5.688 0L4.7 14.77a4.041 4.041 0 0 1-1.174-3.035l.239-5.006A3.108 3.108 0 0 1 6.73 3.761l5-.239A4.026 4.026 0 0 1 14.77 4.7l4.53 4.53a4.027 4.027 0 0 1 0 5.688zM9.5 6.25a3.25 3.25 0 1 0 3.25 3.25A3.254 3.254 0 0 0 9.5 6.25zm0 5a1.75 1.75 0 1 1 1.75-1.75 1.752 1.752 0 0 1-1.75 1.75z" fill="#000000" data-original="#000000" class=""></path><path fill="#fdaa29" d="m17.53 13.53-4 4a.75.75 0 0 1-1.06-1.06l4-4a.75.75 0 0 1 1.06 1.06z" data-original="#fdaa29" style={{display: "none"}}></path></g></g></svg>
                             <p>{item.title}</p>
                         </div>
                        ))
                    }
                    
                </div>
            </div>

            <div className='hotel-facility'>
                <h2>Language</h2>
                <div className='hotel-facilities-icons'>
                    {
                        facilities.slice(0,3).map((item)=>(
                            <div>
                             <svg version="1.1"  width="25" height="25" x="0" y="0" viewBox="0 0 24 24" ><g><g data-name="1"><path d="M20.364 8.17 15.83 3.636a5.529 5.529 0 0 0-4.167-1.612l-5 .239a4.6 4.6 0 0 0-4.4 4.4l-.239 5.006a5.537 5.537 0 0 0 1.612 4.161l4.534 4.534a5.527 5.527 0 0 0 7.809 0l4.385-4.385a5.526 5.526 0 0 0 0-7.809zM19.3 14.918 14.918 19.3a4.027 4.027 0 0 1-5.688 0L4.7 14.77a4.041 4.041 0 0 1-1.174-3.035l.239-5.006A3.108 3.108 0 0 1 6.73 3.761l5-.239A4.026 4.026 0 0 1 14.77 4.7l4.53 4.53a4.027 4.027 0 0 1 0 5.688zM9.5 6.25a3.25 3.25 0 1 0 3.25 3.25A3.254 3.254 0 0 0 9.5 6.25zm0 5a1.75 1.75 0 1 1 1.75-1.75 1.752 1.752 0 0 1-1.75 1.75z" fill="#000000" data-original="#000000" class=""></path><path fill="#fdaa29" d="m17.53 13.53-4 4a.75.75 0 0 1-1.06-1.06l4-4a.75.75 0 0 1 1.06 1.06z" data-original="#fdaa29" style={{display: "none"}}></path></g></g></svg>
                             <p>{item.title}</p>
                         </div>
                        ))
                    }
                    
                </div>
            </div>

            <div className='hotel-facility'>
                <h2>Frequently asked questions</h2>
            <div className='accordians-box'>
            <Accordion onClick={handleFun}>
                <AccordionSummary
                expandIcon={expanded? <AddOutlinedIcon className='accrodian-open-icon' />:<RemoveOutlinedIcon className='accrodian-close-icon'  />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <div className='accordian-heading'>
                    <RiQuestionnaireLine />
                    <h4>See The Beasutiful mountain</h4>
                </div>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                In 1972, the Urraco, which had experienced several initial slowdowns, was finally put into production. Almost inevitably, the S version also arrived in October of that year. In this case, the goal was not to enhance the car's performance but to improve its overall quality, which had been neglected in the haste to start production.
                </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion onClick={handleFun}>
                <AccordionSummary
                expandIcon={expanded? <AddOutlinedIcon className='accrodian-open-icon' />:<RemoveOutlinedIcon className='accrodian-close-icon'  />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <div className='accordian-heading'>
                    <RiQuestionnaireLine />
                    <h4>See The Beasutiful mountain</h4>
                </div>
                </AccordionSummary>
                <AccordionDetails>
                <Typography>
                In 1972, the Urraco, which had experienced several initial slowdowns, was finally put into production. Almost inevitably, the S version also arrived in October of that year. In this case, the goal was not to enhance the car's performance but to improve its overall quality, which had been neglected in the haste to start production.
                </Typography>
                </AccordionDetails>
            </Accordion>
            </div>
            </div>
           

            <div className='rental-map-container'>
                <div className='rental-heading'>
                <h2>Tour's Location</h2>
                <span>
                <p><LocationOnOutlinedIcon /> Los Angeles</p>
                </span>
                </div>
                <img src={rentalMap} />
            </div>

            <div>
                <Reviews type="Tour" />
            </div>

        </section>

        <section className='hotel-right-section'>
        <div className='right-section-stick'>
            <div className='booking-section'>
                <div>
                <div className="hotel-price">
                    <p>From <span>€136.00 </span>/night</p>
                    <div>
                    <FaStar />  <p><span>5</span> (3 reviews)</p>
                    </div>
                </div>

                <div className='hotel-date-selecter'>
                <div className='date-picker'>
                <p>Date</p>
                <SingleDatePicker
                    id="date_input"
                    date={startDate}
                    focused={focusedInput}
                    placeholder="20/2/2020"
                    titleText="Date Pick"
                    ariaLabel="Date"
                    onDateChange={handleDatesChange}
                    onFocusChange={onFocusChange}
                />
                </div>

                <div className='guest-bar' >
            <div className='guest-text-box'>
            <div className='counter-box'>
                    <div className='tour-guest-counter'>
                    <p>Adults</p>
                    <span>Over 18+</span>
                    </div>
                    <div className='counter'>
                    <AddOutlinedIcon onClick={()=>handleGuestCounter("adult","add")} />
                    <p>{counsters.adultCounter}</p>
                    <RemoveOutlinedIcon onClick={()=>handleGuestCounter("adult","sub")} />
                    </div>
                    </div>
                    <div className='counter-box'>
                    <div className='tour-guest-counter'>
                    <p>Children</p>
                    <span>Under 12</span>
                    </div>
                    <div className='counter'>
                    <AddOutlinedIcon onClick={()=>handleGuestCounter("child","add")} />
                    <p>{counsters.childrenCounter}</p>
                    <RemoveOutlinedIcon onClick={()=>handleGuestCounter("child","sub")}/>
                </div>
             </div>
             <div className='counter-box'>
                <div className='tour-guest-counter'>
                    <p>Infant</p>
                    <span>Under 3</span>
                    </div>
                    <div className='counter'>
                    <AddOutlinedIcon onClick={()=>handleGuestCounter("adult","add")} />
                    <p>{counsters.adultCounter}</p>
                    <RemoveOutlinedIcon onClick={()=>handleGuestCounter("adult","sub")} />
                    </div>
            </div>
            </div>

                
                </div>

                </div>
                <div className='total-price'>
                    <p>Total</p>
                    <p>€150.00</p>
                </div>
                <div className='availabilty-check'>
                <button className='primary-btn '>Check Availability</button>
                </div>
                </div>
            </div>

            <div>
                <UserProfile />
            </div>

         
        </div>
        </section>

        </section>

        <div className='recommended-container hotel-container'>
        <h2 className='recommend-heading'>Explore More Options</h2>
        <Slider  {...settings} className='recommended-card'>
            {
              hotelData.map((hotel)=>(
                <TourCard hotel={hotel} />
              ))
            }
        </Slider>
        </div>

        <Footer />
    </div>
  )
}

export default TourDetail

let Highlights = ["Visit eight villages showcasing Polynesian culture","Canoe ride, tattoos, spear throwing, ukulele lessons and fishing","Spectacular Polynesian evening dinner show ‘Ha: Breath of Life’","Optional transportation from Waikiki hotels"]
let hotelBenefit = [
    {
        title:"Specialized bilingual guide",
        status:"yes"
    },
    {
        title:"Additional Services",
        status:"no"
    },
    {
        title:"Private Transport ",
        status:"yes"
    },
    {
        title:"Insurance",
        status:"no"
    },
    {
        title:"Entrance fees (Cable and car and Moon Valley)",
        status:"yes"
    },
    {
        title:"Drink",
        status:"no"
    },
    {
        title:"Box lunch water, banana apple and chocolate",
        status:"yes"
    },
    {
        title:"Tickets",
        status:"no"
    },
]

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className='next-slider-icon'>
      <ArrowForwardIosRoundedIcon  className={className}
      onClick={onClick} />
      </div>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className='previous-slider-icon'>
      <ArrowBackIosNewRoundedIcon  className={className}
      onClick={onClick} />
      </div>
      
    );
  }