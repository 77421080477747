import React, { useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/images/logo.png'
import './header.scss'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ShoppingBasketOutlinedIcon from '@mui/icons-material/ShoppingBasketOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import WifiCalling3OutlinedIcon from '@mui/icons-material/WifiCalling3Outlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, InputLabel, OutlinedInput, Radio, RadioGroup, TextField } from '@mui/material'
import { FormControl } from '@mui/base'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MenuIcon from '@mui/icons-material/Menu';
import { BsBasket2 } from "react-icons/bs";
import EventBus from 'eventing-bus'

const style = {
  position: 'absolute',
  top: '45%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 570,
  backgroundColor: 'white',
  border: '1px solid #DEDEDE',
  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.08), 0px 4px 12px rgba(0, 0, 0, 0.05)",
  padding:"30px 0px",
  borderRadius:"20px",

};

const Header = () => {
  const [dropdown,setDropdown] = useState(false)
  const [open, setOpen] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [loginTab,setLoginTab] = React.useState("signIn");
  const [showCart,setShowCart] = React.useState(false)

  useEffect(() => {
    EventBus.on("loginModal",handleOpen)
  }, [])
  
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <header >

      <div className='top-navbar'>
      <div className='top-navbar-container'>
      <div className='top-navbar-box'>

      <div className='personal-detail'>
        <div>
        <WifiCalling3OutlinedIcon />
        <p>(000) 999 -656 -888</p>
      </div>
      <div>
        <EmailOutlinedIcon />
        <p>email@gmail.com</p>
      </div>
      </div>

      <div className='social-icons'>
      <svg  viewBox="0 0 50 50" width="50px" height="50px">    <path d="M32,11h5c0.552,0,1-0.448,1-1V3.263c0-0.524-0.403-0.96-0.925-0.997C35.484,2.153,32.376,2,30.141,2C24,2,20,5.68,20,12.368 V19h-7c-0.552,0-1,0.448-1,1v7c0,0.552,0.448,1,1,1h7v19c0,0.552,0.448,1,1,1h7c0.552,0,1-0.448,1-1V28h7.222 c0.51,0,0.938-0.383,0.994-0.89l0.778-7C38.06,19.518,37.596,19,37,19h-8v-5C29,12.343,30.343,11,32,11z"/></svg>
      <svg  viewBox="0 0 30 30" width="50px" height="50px">    <path d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z"/></svg>
      <svg  viewBox="0 0 50 50" width="50px" height="50px"><path d="M 16 3 C 8.8324839 3 3 8.8324839 3 16 L 3 34 C 3 41.167516 8.8324839 47 16 47 L 34 47 C 41.167516 47 47 41.167516 47 34 L 47 16 C 47 8.8324839 41.167516 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.086484 5 45 9.9135161 45 16 L 45 34 C 45 40.086484 40.086484 45 34 45 L 16 45 C 9.9135161 45 5 40.086484 5 34 L 5 16 C 5 9.9135161 9.9135161 5 16 5 z M 37 11 A 2 2 0 0 0 35 13 A 2 2 0 0 0 37 15 A 2 2 0 0 0 39 13 A 2 2 0 0 0 37 11 z M 25 14 C 18.936712 14 14 18.936712 14 25 C 14 31.063288 18.936712 36 25 36 C 31.063288 36 36 31.063288 36 25 C 36 18.936712 31.063288 14 25 14 z M 25 16 C 29.982407 16 34 20.017593 34 25 C 34 29.982407 29.982407 34 25 34 C 20.017593 34 16 29.982407 16 25 C 16 20.017593 20.017593 16 25 16 z"/></svg>
      <svg  viewBox="0 0 30 30" width="50px" height="50px">    <path d="M 15 4 C 10.814 4 5.3808594 5.0488281 5.3808594 5.0488281 L 5.3671875 5.0644531 C 3.4606632 5.3693645 2 7.0076245 2 9 L 2 15 L 2 15.001953 L 2 21 L 2 21.001953 A 4 4 0 0 0 5.3769531 24.945312 L 5.3808594 24.951172 C 5.3808594 24.951172 10.814 26.001953 15 26.001953 C 19.186 26.001953 24.619141 24.951172 24.619141 24.951172 L 24.621094 24.949219 A 4 4 0 0 0 28 21.001953 L 28 21 L 28 15.001953 L 28 15 L 28 9 A 4 4 0 0 0 24.623047 5.0546875 L 24.619141 5.0488281 C 24.619141 5.0488281 19.186 4 15 4 z M 12 10.398438 L 20 15 L 12 19.601562 L 12 10.398438 z"/></svg>
      </div>
      </div>
      </div>
      </div>

      <div className='header-container'>
        <div>
            <div className='main-header'>

            <div className='mobile-drawer'>
              <MenuIcon />
            </div>
            <div className='logo-image'>
              <Link to='/'>
                <img src={logo} />
              </Link>
            </div>

            <div className='nav-bar'>
              <div className='navbar-box'>
                <ul className='navbars-main'>
                   <li><Link className="active-nav" to="/">Home</Link></li>
                   <li>
                    <Link  to="#">Hotel 
                    {/* <KeyboardArrowDownRoundedIcon /> */}
                    </Link>
                    {/* <ul className='dropdown'>
                     <li><Link to="/">Hotel 1</Link></li>
                     <li><Link to="/">Hotel 1</Link></li>
                     <li><Link to="/">Hotel 1</Link></li>
                      <li><Link to="/">Hotel 1</Link></li>
                      <li><Link to="/">Hotel 1</Link></li>
                    </ul> */}
                   </li>
                  
                  
                  
                   <li>
                    <Link to="#">Activity 
                    {/* <KeyboardArrowDownRoundedIcon /> */}
                    </Link>
                    {/* <ul className='dropdown'>
                     <li><Link to="/">Activity 1</Link></li>
                     <li><Link to="/">Activity 1</Link></li>
                     <li><Link to="/">Activity 1</Link></li>
                     <li><Link to="/">Activity 1</Link></li>

                   </ul> */}
                    </li>
                  
                   <li>
                    <Link to="#">Tour
                     {/* <KeyboardArrowDownRoundedIcon /> */}
                     </Link>
                    {/* <ul className='dropdown'>
                     <li><Link to="/">Activity 1</Link></li>
                     <li><Link to="/">Activity 1</Link></li>
                     <li><Link to="/">Activity 1</Link></li>
                     <li><Link to="/">Activity 1</Link></li>
                   </ul> */}
                    </li>
                  
                   <li>
                    <Link to="#">Rental 
                    {/* <KeyboardArrowDownRoundedIcon /> */}
                    </Link>
                   {/* <ul className='dropdown'>
                     <li><Link to="/">Rental 1</Link></li>
                     <li><Link to="/">Rental 1</Link></li>
                     <li><Link to="/">Rental 1</Link></li>
                     <li><Link to="/">Rental 1</Link></li>
                   </ul> */}
                   </li>
                 
                   <li>
                    <Link to="#">Car 
                    {/* <KeyboardArrowDownRoundedIcon /> */}
                    </Link>
                    {/* <ul className='dropdown'>
                     <li><Link to="/">Car 1</Link></li>
                     <li><Link to="/">Car 1</Link></li>
                     <li><Link to="/">Car 1</Link></li>
                     <li><Link to="/">Car 1</Link></li>
                     <li><Link to="/">Car 1</Link></li>
                   </ul> */}
                    </li>
                   
                   <li>
                    <Link to="#">Pages 
                    {/* <KeyboardArrowDownRoundedIcon /> */}
                    </Link>
                    {/* <ul className='dropdown'>
                     <li><Link to="/">Pages 1</Link></li>
                     <li><Link to="/">Pages 1</Link></li>
                     <li><Link to="/">Pages 1</Link></li>
                     <li><Link to="/">Pages 1</Link></li>

                   </ul> */}
                    </li>
                   
                </ul>
              </div>
            </div>

            <div className='right-navbar'>
                <div className='currency' onClick={()=>setDropdown(!dropdown)}>
                    <p>EUR</p>
                    <KeyboardArrowDownIcon />
                {dropdown &&
                <div className='dropdown-onClik'>
                <p>USD</p>
                <p>EUR</p>
                <p>GPB</p>
                </div>
                }
                </div>
                <div className='card-section-icon'>
                <BsBasket2 onClick={()=>setShowCart(!showCart)} className='side-svg'/>
                {showCart &&
                  <div className='cart-dropdown'>
                    <h5>Your Cart</h5>
                    <p>Your Cart is Empty</p>
                  </div>
                }
                </div>
                <PermIdentityOutlinedIcon onClick={handleOpen} className='side-svg' />
                
                <button>Become a Host</button>
            </div>
        </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className='login-modal' style={style}>
          <div className='modal-header'>
            <CloseIcon style={{cursor:"pointer"}} onClick={handleClose} />
            <div className='login-tab'>
              <p className={loginTab == "signIn" ? "active-login-tab" : "not-active-login-tab" } onClick={() => setLoginTab("signIn")}>Sign In</p>
              <p className={loginTab == "signUp" ? "active-login-tab" : "not-active-login-tab" } onClick={() => setLoginTab("signUp")}>Sign Up</p>
            </div>
          </div>

        {
          loginTab == "signIn"  &&
            <div className='login-form-container'>
                <h2>Sign in to your account</h2>
                <TextField id="outlined-basic" label="Email or Username" variant="outlined" />
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      placeholder='Password'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>
              
              <button className='primary-btn'>Login</button>
              <div className='forgot-password'>
              <FormControlLabel control={<Checkbox defaultChecked />} label="Remember Me" />
              <p>Forgot Password?</p>
              </div>

              <p className='create-account'>Create an Account</p>
            </div>
        }
        {loginTab == "signUp"  &&
          <div className='login-form-container'>
            <h2>Create an account</h2>
            <TextField id="outlined-basic" label="Username" variant="outlined" />
            <TextField id="outlined-basic" label="Full Name" variant="outlined" />
            <TextField id="outlined-basic" label="Email" variant="outlined" />
            <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      placeholder='Password'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label="Password"
              />
            </FormControl>

            <FormControl>
              <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
              >
                <FormControlLabel value="female" control={<Radio />} label="Normal User" />
                <FormControlLabel value="male" control={<Radio />} label="Partner User" />
              </RadioGroup>
            </FormControl>

            <button className='primary-btn'>Register</button>
            <FormControlLabel control={<Checkbox defaultChecked />} label="I confirm that I have read the privacy policy" />

          </div>
        }
        </div>
      </Modal>
    </header>
  )
}

export default Header