export const blogData = [
    {
        background:"https://images.pexels.com/photos/6181092/pexels-photo-6181092.jpeg?auto=compress&cs=tinysrgb&w=800",
        title:"Why do we use it?",
        desc:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
        status:"Travel",
    },
    {
         background:"https://images.pexels.com/photos/1371360/pexels-photo-1371360.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        title:"Why do we use it?",
        desc:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
        status:"Booking",
    },
    {
         background:"https://images.pexels.com/photos/4436363/pexels-photo-4436363.jpeg?auto=compress&cs=tinysrgb&w=800",
        title:"Why do we use it?",
        desc:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
        status:"Travel",
    },
    {
         background:"https://images.pexels.com/photos/2563681/pexels-photo-2563681.jpeg?auto=compress&cs=tinysrgb&w=800",
        title:"Why do we use it?",
        desc:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
        status:"Travel",
    },
    {
         background:"https://images.pexels.com/photos/3155666/pexels-photo-3155666.jpeg?auto=compress&cs=tinysrgb&w=800",
        title:"Why do we use it?",
        desc:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
        status:"Travel",
    },
    {
        background:"https://images.pexels.com/photos/6181092/pexels-photo-6181092.jpeg?auto=compress&cs=tinysrgb&w=800",
        title:"Why do we use it?",
        desc:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
        status:"Travel",
    },
    {
        background:"https://images.pexels.com/photos/6181092/pexels-photo-6181092.jpeg?auto=compress&cs=tinysrgb&w=800",
        title:"Why do we use it?",
        desc:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. ",
        status:"Travel",
    },
]