import React,{useState} from 'react'
import 'react-dates/lib/css/_datepicker.css';
import { DateRangePicker, SingleDatePicker, DayPickerRangeController } from 'react-dates';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import './hotel-search-bar.scss'
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { locations } from '../../../DataFiles/Hotel';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { FaSearch } from "react-icons/fa";
import { ClickAwayListener } from '@mui/material';
import { RxCalendar} from "react-icons/rx";

const HotelSearchBar = ({
  searchType
}) => {
  const [startDate, setStartDate] = useState(null);  
  const [endDate, setEndDate] = useState(null);  
  const [focusedInput, setFocusedInput] = useState(null);
  const [showLocation,setShowLocation] = useState(false);
  const [showGuest,setShowGuest] = useState(false)
  const [counsters,setCounsters] = useState({
    adultCounter:1,
    childrenCounter:1,
    roomCounter:1
  })

  const handleGuestCounter = (type,calc) =>{
    let {roomCounter,adultCounter,childrenCounter} = counsters
    if(type == "room"){
      if(calc == "add"){
      setCounsters({...counsters,roomCounter:roomCounter+1})
      }else{
        setCounsters({...counsters,roomCounter:roomCounter-1})
      }
    }else if(type == "adult"){
      if(calc == "add"){
        setCounsters({ ...counsters, adultCounter:adultCounter+1 })
      }else{
        setCounsters({ ...counsters, adultCounter:adultCounter-1 })
      }
     
    }else {
      if(calc == "add"){
        setCounsters({ ...counsters, childrenCounter:childrenCounter + 1})
      }else{
        setCounsters({ ...counsters, childrenCounter:childrenCounter - 1})
      }
  
    }
  }

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
     setEndDate(endDate);  
  };

  return (
    <div className={(searchType === "hotel" || searchType == "hotelRental") ?'hotel-search-bar':'hotel-search-bar other-search-bar'}>
      <div className='location-bar'>
      <ClickAwayListener onClickAway={()=>setShowLocation(false)}>
        <div onClick={()=>setShowLocation(!showLocation)} className='heading'>
        <FmdGoodOutlinedIcon />
        <div className='location-text'>
        <p className='location-heading-top'>Location</p>
        <p className='inof-text'>Where you are going?</p>
        </div>
        </div>
        </ClickAwayListener>
        
        {showLocation &&
          <div className='location-dropdown'>
          <p className='location-heading'>Popular Location</p>
          <div>
            <span>United state</span>
            {
              locations.map((location,index)=>(
                <div className='locations'>
                  <FmdGoodOutlinedIcon />
                  <p>{location}</p>
                </div>
              ))
            }
          </div>
        </div>
        }
      </div>
      <div className='date-picker'>
        <p className='check-in-text'>{(searchType == "hotel" || searchType == "hotelRental") ? "Check In":searchType == "carRental" ?"Pick-up":"Date"}</p>
        <p className='check-out-tct'>{searchType == "carRental"?"Drop-off":"Check Out"}</p>
        <CalendarMonthOutlinedIcon className='date-end-calender' />
        <DateRangePicker        
          startDate={startDate} 
          placeholderText={'Please select a date'}        
          startDateId="date_picker_start_date_id"
          endDate={endDate}   
          startDatePlaceholderText="Add Date"    
          endDatePlaceholderText="Add Date" 
          customInputIcon={<CalendarMonthOutlinedIcon />} 
          startDateTitleText="Add Date"
          startDateAriaLabel="Add Date"
          endDateAriaLabel="Add Date"
          endDateTitleText="Add Date"
          endDateId={searchType == "carRental"?"date_picker_end_date_id1":"date_picker_end_date_id" }       
          onDatesChange={handleDatesChange}       
          focusedInput={focusedInput}        
          onFocusChange={focusedInput => setFocusedInput(focusedInput)}
          // isOutsideRange={isOutsideRange}
          />
        </div>

       {
        (searchType === "hotel" || searchType == "hotelRental") &&
        <div className='guest-bar' >
          <GroupOutlinedIcon className={searchType == "hotelRental" ? "guest-svgIcon":""} />
          <ClickAwayListener onClickAway={()=>setShowGuest(false)}>
          <div onClick={()=>setShowGuest(!showGuest)} className={searchType === "hotel"? 'guest-text-box':'guest-text-box rentalguest-box'}>
          <p>Guests</p>
          <span className='room-guests'>1 Guest {searchType == "hotel" && "1 Room"}</span>
          </div>
          </ClickAwayListener>

         { showGuest &&
         <div className='guest-dropdown'>
            <div className='counter-box'>
            <p>Rooms</p>
            <div className='counter'>
            <AddOutlinedIcon onClick={()=>handleGuestCounter("room","add")} />
            <p>{counsters.roomCounter}</p>
            <RemoveOutlinedIcon onClick={()=>handleGuestCounter("room","sub")} />
           </div>
            </div>
            <div className='counter-box'>
            <p>Adults</p>
            <div className='counter'>
            <AddOutlinedIcon onClick={()=>handleGuestCounter("adult","add")} />
            <p>{counsters.adultCounter}</p>
            <RemoveOutlinedIcon onClick={()=>handleGuestCounter("adult","sub")} />
            </div>
            </div>
            <div className='counter-box'>
            <p>Children</p>
            <div className='counter'>
            <AddOutlinedIcon onClick={()=>handleGuestCounter("child","add")} />
            <p>{counsters.childrenCounter}</p>
            <RemoveOutlinedIcon onClick={()=>handleGuestCounter("child","sub")}/>
          </div>
            </div>
          </div>
          }
        </div>
        }

        <button className={searchType === "hotel" || searchType == "hotelRental"?'second-btn':"primary-btn "}>
          <FaSearch />
          Search
        </button>

 
    </div>
  )
}

export default HotelSearchBar