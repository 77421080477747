import React,{ useState,useEffect } from 'react'
import BreadCrumb from '../../Component/BreadCrumb'
import ImageCarousel from '../../Component/ImageCarousel'
import { FaStar,FaFacebookF,FaTwitter,FaPinterestP,FaLinkedinIn,FaUserTie } from "react-icons/fa";
import { RiShareForwardLine,RiHeart3Line } from "react-icons/ri";
import Footer from '../../Component/Footer';
import Reviews from '../../Component/Reviews';
import { facilities } from '../../DataFiles/Hotel'
import { DateRangePicker,DayPickerRangeController } from 'react-dates';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import UserProfile from '../../Component/UserProfile';
import './rental-detail.scss'
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import { RxDimensions } from "react-icons/rx";
import ChildCareOutlinedIcon from '@mui/icons-material/ChildCareOutlined';
import moment from 'moment'
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import rentalMap from '../../assets/images/rentalmap.png'
import Slider from 'react-slick';
import RentalCard from '../../Component/ListingCard/RentalCard';
import { hotelData } from '../../DataFiles/Hotel'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import { ClickAwayListener } from '@mui/material';
import EventBus from 'eventing-bus'

const RentalDetail = () => {
    const [showSocialIcon,setShowSocialIcon]= useState(false)
    const [startDate, setStartDate] = useState(null);  
    const [endDate, setEndDate] = useState(null);  
    const [focusedInput, setFocusedInput] = useState(null);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [])

    const [counsters,setCounsters] = useState({
        adultCounter:1,
        childrenCounter:1,
        roomCounter:1
      })

    const handleGuestCounter = (type,calc) =>{
        let {roomCounter,adultCounter,childrenCounter} = counsters
        if(type == "room"){
          if(calc == "add"){
          setCounsters({...counsters,roomCounter:roomCounter+1})
          }else{
            setCounsters({...counsters,roomCounter:roomCounter-1})
          }
        }else if(type == "adult"){
          if(calc == "add"){
            setCounsters({ ...counsters, adultCounter:adultCounter+1 })
          }else{
            setCounsters({ ...counsters, adultCounter:adultCounter-1 })
          }
         
        }else {
          if(calc == "add"){
            setCounsters({ ...counsters, childrenCounter:childrenCounter + 1})
          }else{
            setCounsters({ ...counsters, childrenCounter:childrenCounter - 1})
          }
      
        }
    }

    const handleDatesChange = ({ startDate, endDate }) => {
        setStartDate(startDate);
         setEndDate(endDate);  
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };

  const handleLoginModal = ()=>{
    EventBus.publish('loginModal')
  }
  return (
    <div className='hotel-detail-container rentail-detail-container'>
        <BreadCrumb title="Hotel Askorav" />
        <div className='hotel-reviews-box'>
           
            <div className='reviews-tours'>
            <div style={{width:"18%"}} className='review-box'>
                   <FaStar />
                <span>5</span>
                <p className='review-no'>(3 Reviews)</p>
                <p className='location'>Los Angeles</p>
            </div>
           
            <div className='share-icons'>
            <ClickAwayListener onClickAway={()=>setShowSocialIcon(false)}>
                <span  onClick={()=>setShowSocialIcon(!showSocialIcon)}  className='share-icon'>
                <RiShareForwardLine/>
                {showSocialIcon &&
                    <div className='social-share-icons'>
                    <span>
                    <FaFacebookF />
                    </span>
                    <span>
                    <FaTwitter />
                    </span>
                    <span>
                    <FaPinterestP />
                    </span>
                    <span>
                    <FaLinkedinIn />
                    </span>
                </div>
                }
                </span>
                </ClickAwayListener>

                <span onClick={handleLoginModal} className='like-icons'>
                <RiHeart3Line />
                </span>
            </div>
          
            </div>
            </div>

        <ImageCarousel type='rental' />

        <section className='hotel-detail-section hotel-container'>
            <section className='hotel-content-section'>
           
            <div className='icons-div'>
                <div>
                    <RxDimensions />
                    <p>Area:  170m2</p>
                </div>
                <div>
                    <BedOutlinedIcon />
                    <p>Rooms:  3</p>
                </div>
                
                <div>
                    <FaUserTie />
                    <p>Adult:  3</p>
                </div>
                <div>
                    <ChildCareOutlinedIcon />
                    <p>Children:  3</p>
                </div>
            </div>

            <div className='hotel-info'>
                <h2>About this hotel</h2>
                <p>Nestled in the heart of Torrance, Redac Gateway Hotel in Torrance is an ideal spot from which to discover Los Angeles (CA). From here, guests can enjoy easy access to all that the lively city has to offer. With its convenient location, the hotel offers easy access to the city’s must-see destinations.</p>
                <p>At Redac Gateway Hotel in Torrance, the excellent service and superior facilities make for an unforgettable stay. Guests of the hotel can enjoy on-site features like free Wi-Fi in all rooms, 24-hour front desk, facilities for disabled guests, express check-in/check-out, luggage storage.</p>
                <p>Experience high quality room facilities during your stay here. Some rooms include television LCD/plasma screen, carpeting, linens, mirror, sofa, provided to help guests recharge after a long day. Besides, the hotel’s host of recreational offerings ensures you have plenty to do during your stay. Redac Gateway Hotel in Torrance is an ideal place of stay for travelers seeking charm, comfort and convenience in Los Angeles (CA).</p>

            </div>

            <div className='hotel-facility'>
                <h2>Amenities</h2>
                <div className='hotel-facilities-icons'>
                    {
                        facilities.slice(0,6).map((item)=>(
                            <div>
                            <span> {item.icon}</span>
                             <p>{item.title}</p>
                         </div>
                        ))
                    }
                    
                </div>
            </div>

            <div className='hotel-facility'>
                <h2>Suitability</h2>
                <div className='hotel-facilities-icons'>
                    {
                        facilities.slice(0,6).map((item)=>(
                            <div>
                            <span> {item.icon}</span>
                             <p>{item.title}</p>
                         </div>
                        ))
                    }
                    
                </div>
            </div>

            <div className='hotel-facility'>
                <h2>Rental Types</h2>
                <div className='hotel-facilities-icons'>
                    {
                        facilities.slice(0,4).map((item)=>(
                            <div>
                            <span> {item.icon}</span>
                             <p>{item.title}</p>
                         </div>
                        ))
                    }
            </div>
            </div>

            <div>
            <div className='tour-calender-selecter'>
                <h2>Rates & availability</h2>
                <div className='date-picker'>
                <DayPickerRangeController
                     startDate={startDate} 
                    endDate={endDate} 
                    numberOfMonths={2}
                    onDatesChange={handleDatesChange}   
                    focusedInput={focusedInput}  
                    onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                    initialVisibleMonth={() => moment().add(2, "M")}
                    />
                </div>

                </div>
            </div>

            <div className='rental-map-container'>
                <div className='rental-heading'>
                <h2>Rental's Location</h2>
                <span>
                <p><LocationOnOutlinedIcon /> Los Angeles</p>
                </span>
                </div>
                <img src={rentalMap} />
            </div>

            <div>
                <Reviews type="Rental" />
            </div>

        </section>

        <section className='hotel-right-section'>
        {/* <div className='right-section-stick'> */}
            <div className='booking-section'>
                <div>
                <div className="hotel-price">
                    <p>From : <span>€136.00 </span>/night</p>
                    <div>
                    <FaStar />  <p><span>5</span> (3 reviews)</p>
                    </div>
                </div>

                <div className='hotel-date-selecter'>
                <div className='date-picker'>
                    <DateRangePicker        
                    startDate={startDate} 
                    placeholderText={'Please select a date'}        
                    startDateId="date_picker_start_date_id"
                    endDate={endDate}   
                    startDatePlaceholderText="Check In"    
                    endDatePlaceholderText="Check Out"
                    startDateTitleText="Check In"
                    startDateAriaLabel="Check In"
                    endDateAriaLabel="Check Out"
                    endDateTitleText="Check Out"
                    endDateId="date_picker_end_date_id"        
                    onDatesChange={handleDatesChange}       
                    focusedInput={focusedInput}        
                    onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                    // isOutsideRange={isOutsideRange}
                    />
                </div>

                <div className='guest-bar' >
            <div className='guest-text-box'>
            <div className='counter-box'>
                    <p>Adults</p>
                    <div className='counter'>
                    <AddOutlinedIcon onClick={()=>handleGuestCounter("adult","add")} />
                    <p>{counsters.adultCounter}</p>
                    <RemoveOutlinedIcon onClick={()=>handleGuestCounter("adult","sub")} />
                    </div>
                    </div>
                    <div className='counter-box'>
                    <p>Children</p>
                    <div className='counter'>
                    <AddOutlinedIcon onClick={()=>handleGuestCounter("child","add")} />
                    <p>{counsters.childrenCounter}</p>
                    <RemoveOutlinedIcon onClick={()=>handleGuestCounter("child","sub")}/>
                </div>
             </div>

             <div className='guest-name'>
                <p>Guest Name *</p>
                <div className='guest-name-field'>
                <select>
                  <option>Mr.</option>
                  <option>Miss.</option>
                  <option>Mrs.</option>
                  </select>
                  <input type='text' placeholder='Guest Name'/>
                </div>
               
             </div>
            </div>

                
                </div>

                </div>
                <div className='availabilty-check'>
                <button className='primary-btn '>Check Availability</button>
                </div>
                </div>
            </div>

            <div>
                <UserProfile />
            </div>

            <div className='information-contact'>
                <h2>Information Contact</h2>
                <div>
                    <p>Email</p>
                    <span>contact@travelerwp.com</span>
                </div>
                <div>
                    <p>Website</p>
                    <span>travelerwp.com</span>
                </div>
                <div>
                    <p>Phone</p>
                    <span>+123456789</span>
                </div>
                <div>
                    <p>Fax</p>
                    <span>+123456789</span>
                </div>
            </div>
        {/* </div> */}
        </section>

        </section>

        <div className='recommended-container hotel-container'>
        <h2 className='recommend-heading'>Explore More Options</h2>
        <Slider  {...settings} className='recommended-card'>
            {
              hotelData.map((hotel)=>(
                <RentalCard hotel={hotel} type="Rental" />
              ))
            }
        </Slider>
        </div>

        <Footer />
    </div>
  )
}

export default RentalDetail



function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className='next-slider-icon'>
      <ArrowForwardIosRoundedIcon  className={className}
      onClick={onClick} />
      </div>
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div className='previous-slider-icon'>
      <ArrowBackIosNewRoundedIcon  className={className}
      onClick={onClick} />
      </div>
      
    );
  }