import React,{ useState,useEffect } from 'react'
import BreadCrumb from '../../Component/BreadCrumb'
import ImageCarousel from '../../Component/ImageCarousel'
import './hotel-detail.scss'
import { FaStar,FaFacebookF,FaTwitter,FaPinterestP,FaLinkedinIn,FaUserTie } from "react-icons/fa";
import { RiShareForwardLine,RiHeart3Line } from "react-icons/ri";
import Footer from '../../Component/Footer';
import Reviews from '../../Component/Reviews';
import { facilities } from '../../DataFiles/Hotel'
import { DateRangePicker } from 'react-dates';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { ClickAwayListener, TextField, Tooltip } from '@mui/material';
import fullmap from '../../assets/images/fullmap.png'
import UserProfile from '../../Component/UserProfile';
import BedOutlinedIcon from '@mui/icons-material/BedOutlined';
import { RxDimensions } from "react-icons/rx";
import ChildCareOutlinedIcon from '@mui/icons-material/ChildCareOutlined';
import EventBus from 'eventing-bus'

const HotelDetail = () => {
    const [showSocialIcon,setShowSocialIcon]= useState(false)
    const [booking,setBooking] = useState("Book")
    const [startDate, setStartDate] = useState(null);  
    const [endDate, setEndDate] = useState(null);  
    const [focusedInput, setFocusedInput] = useState(null);
    const [showGuest,setShowGuest] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const [counsters,setCounsters] = useState({
        adultCounter:1,
        childrenCounter:1,
        roomCounter:1
      })
    
    const handleGuestCounter = (type,calc) =>{
        let {roomCounter,adultCounter,childrenCounter} = counsters
        if(type == "room"){
          if(calc == "add"){
          setCounsters({...counsters,roomCounter:roomCounter+1})
          }else{
            setCounsters({...counsters,roomCounter:roomCounter-1})
          }
        }else if(type == "adult"){
          if(calc == "add"){
            setCounsters({ ...counsters, adultCounter:adultCounter+1 })
          }else{
            setCounsters({ ...counsters, adultCounter:adultCounter-1 })
          }
         
        }else {
          if(calc == "add"){
            setCounsters({ ...counsters, childrenCounter:childrenCounter + 1})
          }else{
            setCounsters({ ...counsters, childrenCounter:childrenCounter - 1})
          }
      
        }
    }

    const handleDatesChange = ({ startDate, endDate }) => {
        setStartDate(startDate);
         setEndDate(endDate);  
    };

    const handleLoginModal = ()=>{
        EventBus.publish('loginModal')
    }
  return (
    <div className='hotel-detail-container'>
        <BreadCrumb title="Hotel Askorav" />
        <ImageCarousel type='hotel' />

        <section className='hotel-detail-section hotel-container'>
            <section className='hotel-content-section'>
            <div className='hotel-reviews-box'>
            <div className='review-stars'>
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
                <FaStar />
                </div>
            <div className='reviews-tours'>
            <div className='review-box'>
                <div className='review-number'>
                    5/5
                </div>
                <span>Excellent</span>
                <p className='review-no'>(3 Reviews)</p>
                <p className='location'>Los Angeles</p>
            </div>
            
            <div className='share-icons'>
            <ClickAwayListener onClickAway={()=>setShowSocialIcon(false)}>
                <span onClick={()=>setShowSocialIcon(!showSocialIcon)} className='share-icon'>
                <RiShareForwardLine  />
                {showSocialIcon &&
                    <div className='social-share-icons'>
                    <span>
                    <FaFacebookF />
                    </span>
                    <span>
                    <FaTwitter />
                    </span>
                    <span>
                    <FaPinterestP />
                    </span>
                    <span>
                    <FaLinkedinIn />
                    </span>
                </div>
                }
                </span>
            </ClickAwayListener>

                <span onClick={handleLoginModal}  className='like-icons'>
                <RiHeart3Line />
                </span>
            </div>
          
            </div>
            </div>

            <div className='hotel-info'>
                <h2>About this hotel</h2>
                <p>Nestled in the heart of Torrance, Redac Gateway Hotel in Torrance is an ideal spot from which to discover Los Angeles (CA). From here, guests can enjoy easy access to all that the lively city has to offer. With its convenient location, the hotel offers easy access to the city’s must-see destinations.</p>
                <p>At Redac Gateway Hotel in Torrance, the excellent service and superior facilities make for an unforgettable stay. Guests of the hotel can enjoy on-site features like free Wi-Fi in all rooms, 24-hour front desk, facilities for disabled guests, express check-in/check-out, luggage storage.</p>
                <p>Experience high quality room facilities during your stay here. Some rooms include television LCD/plasma screen, carpeting, linens, mirror, sofa, provided to help guests recharge after a long day. Besides, the hotel’s host of recreational offerings ensures you have plenty to do during your stay. Redac Gateway Hotel in Torrance is an ideal place of stay for travelers seeking charm, comfort and convenience in Los Angeles (CA).</p>

            </div>

            <div className='hotel-facility'>
                <h2>Hotel Facilities</h2>
                <div className='hotel-facilities-icons'>
                    {
                        facilities.map((item)=>(
                            <div>
                            <span> {item.icon}</span>
                             <p>{item.title}</p>
                         </div>
                        ))
                    }
                </div>
            </div>

            <div className='hotel-rules'>
            <h2>Rules</h2>
                <div className='checkin-time'>
                    <p>Check In	</p>
                    <p>12:00 pm</p>
                </div>
                <div className='checkin-time'>
                    <p>Check Out	</p>
                    <p>11:00 pm</p>
                </div>
            </div>

            <div className='availaibity-card'>
                <h2>Availability</h2>
                <div className='availabitly-card'>
                    <div className='card-image'>
                        <img src="https://images.pexels.com/photos/164595/pexels-photo-164595.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />
                    </div>
                    <div className='icons-box'>
                        <h2>Standard Double Room</h2>
                        <div className='icons-div'>
                            <div>
                                <Tooltip title="Room Footage">
                                <RxDimensions />
                                </Tooltip>
                                <p>170m2</p>
                            </div>
                            <div>
                            <Tooltip title="No of Bed">
                                <BedOutlinedIcon />
                            </Tooltip>
                                <p>x3</p>
                            </div>
                            
                            <div>
                            <Tooltip title="No of Adult">
                             <FaUserTie />
                             </Tooltip>
                             <p>x3</p>
                            </div>
                            <div>
                            <Tooltip title="No of Children">
                                <ChildCareOutlinedIcon />
                            </Tooltip>
                                <p>x3</p>
                            </div>
                        </div>
                    </div>
                    <div className='price-btn'>
                        <button className='primary-btn'>Show Price</button>
                    </div>
                </div>
                <div className='availabitly-card'>
                    <div className='card-image'>
                        <img src="https://images.pexels.com/photos/210265/pexels-photo-210265.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />
                    </div>
                    <div className='icons-box'>
                        <h2>Standard Double Room</h2>
                        <div className='icons-div'>
                            <div>
                                <Tooltip title="Room Footage">
                                <RxDimensions />
                                </Tooltip>
                                <p>170m2</p>
                            </div>
                            <div>
                            <Tooltip title="No of Bed">
                                <BedOutlinedIcon />
                            </Tooltip>
                                <p>x3</p>
                            </div>
                            
                            <div>
                            <Tooltip title="No of Adult">
                             <FaUserTie />
                             </Tooltip>
                             <p>x3</p>
                            </div>
                            <div>
                            <Tooltip title="No of Children">
                                <ChildCareOutlinedIcon />
                            </Tooltip>
                                <p>x3</p>
                            </div>
                        </div>
                    </div>
                    <div className='price-btn'>
                        <button className='primary-btn'>Show Price</button>
                    </div>
                </div>
                <div className='availabitly-card'>
                    <div className='card-image'>
                        <img src="https://images.pexels.com/photos/262048/pexels-photo-262048.jpeg?auto=compress&cs=tinysrgb&w=800" />
                    </div>
                    <div className='icons-box'>
                        <h2>Standard Double Room</h2>
                        <div className='icons-div'>
                            <div>
                                <Tooltip title="Room Footage">
                                <RxDimensions />
                                </Tooltip>
                                <p>170m2</p>
                            </div>
                            <div>
                            <Tooltip title="No of Bed">
                                <BedOutlinedIcon />
                            </Tooltip>
                                <p>x3</p>
                            </div>
                            
                            <div>
                            <Tooltip title="No of Adult">
                             <FaUserTie />
                             </Tooltip>
                             <p>x3</p>
                            </div>
                            <div>
                            <Tooltip title="No of Children">
                                <ChildCareOutlinedIcon />
                            </Tooltip>
                                <p>x3</p>
                            </div>
                        </div>
                    </div>
                    <div className='price-btn'>
                        <button className='primary-btn'>Show Price</button>
                    </div>
                </div>

            </div>

            <div>
                <Reviews type="Hotel" />
            </div>

        </section>

        <section className='hotel-right-section'>
            <div className='right-section-stick'>
            <div className='booking-section'>
                <div className='booking-tabs-btn'>
                <button className={booking == "Book"?"primary-btn":"not-active-btn"} onClick={()=>setBooking("Book")} >Book</button>
                <button className={booking == "Inquiry"?"primary-btn":"not-active-btn"} onClick={()=>setBooking("Inquiry")}>Inquiry</button>
            </div>

           { booking == "Book" &&
            <div>
            <div className="hotel-price">
                <p>From <span>€136.00 </span>/night</p>
                <div>
                <FaStar />  <p><span>5</span> (3 reviews)</p>
                </div>
            </div>

            <div className='hotel-date-selecter'>
            <div className='date-picker'>
                <DateRangePicker        
                startDate={startDate} 
                placeholderText={'Please select a date'}        
                startDateId="date_picker_start_date_id"
                endDate={endDate}   
                startDatePlaceholderText="Check In"    
                endDatePlaceholderText="Check Out"
                startDateTitleText="Check In"
                startDateAriaLabel="Check In"
                endDateAriaLabel="Check Out"
                endDateTitleText="Check Out"
                endDateId="date_picker_end_date_id"        
                onDatesChange={handleDatesChange}       
                focusedInput={focusedInput}        
                onFocusChange={focusedInput => setFocusedInput(focusedInput)}
                // isOutsideRange={isOutsideRange}
                />
            </div>

            <div className='guest-bar' >
            <ClickAwayListener onClickAway={()=>setShowGuest(false)}>
          <div onClick={()=>setShowGuest(!showGuest)} className='guest-text-box'>
          <p >Guests</p>
          <span className='room-guests'>1 Guest ,1 Room</span>
          </div>
          </ClickAwayListener>

         { showGuest &&
         <div className='guest-dropdown'>
            <div className='counter-box'>
            <p>Rooms</p>
            <div className='counter'>
            <AddOutlinedIcon onClick={()=>handleGuestCounter("room","add")} />
            <p>{counsters.roomCounter}</p>
            <RemoveOutlinedIcon onClick={()=>handleGuestCounter("room","sub")} />
           </div>
            </div>
            <div className='counter-box'>
            <p>Adults</p>
            <div className='counter'>
            <AddOutlinedIcon onClick={()=>handleGuestCounter("adult","add")} />
            <p>{counsters.adultCounter}</p>
            <RemoveOutlinedIcon onClick={()=>handleGuestCounter("adult","sub")} />
            </div>
            </div>
            <div className='counter-box'>
            <p>Children</p>
            <div className='counter'>
            <AddOutlinedIcon onClick={()=>handleGuestCounter("child","add")} />
            <p>{counsters.childrenCounter}</p>
            <RemoveOutlinedIcon onClick={()=>handleGuestCounter("child","sub")}/>
          </div>
            </div>
          </div>
          }
             </div>

            </div>
            <div className='availabilty-check'>
             <button className='primary-btn '>Check Availability</button>
            </div>
            </div>
            }

            {booking == "Inquiry" &&
            <div className='inquiry-fields-box'>
                <div className='inquiry-field'>
                <label>Name (*)</label>
                <TextField id="outlined-basic" variant="outlined" />
                </div>
                <div className='inquiry-field'>
                <label>E-mail (*)</label>
                <TextField id="outlined-basic" variant="outlined" />
                </div>
                <div className='inquiry-field'>
                <label>Phone</label>
                <TextField id="outlined-basic" variant="outlined" />
                </div>
                <div className='inquiry-field'>
                <label>Note</label>
                <TextField   multiline rows={3} id="outlined-basic" variant="outlined" />
                </div>
                <button className='primary-btn'>Send Message</button>
            </div>
            }

            </div>

            <div className='side-bar-image'>
                <img src="https://images.pexels.com/photos/936722/pexels-photo-936722.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />
            </div>

            <div className='google-map'>
                <img src={fullmap } />
            </div>

            <div>
                <UserProfile />
            </div>
          </div>
        </section>

        </section>

        <Footer />
    </div>
  )
}

export default HotelDetail

