import React from 'react'
import './blog-card.scss'

const BlogCard = ({blog}) => {
  return (
    <div className='blog-card'>
      <div className='blog-image-box'>
        <div className='background-img' style={{backgroundImage: "url(" + blog.background + ")"}}>
        </div>
        </div>
        <div className='blog-details'>
            <button className={blog.status == "Travel"?"travel":"booking"}>{blog.status}</button>
            <h2>{blog.title}</h2>
            <p>{blog.desc}</p>
        </div>
    </div>
  )
}

export default BlogCard
