import React from 'react'
import './property-card.scss'
import { FaStar,FaRegHeart } from "react-icons/fa";
import { Link } from 'react-router-dom';


const PropertyCard = ({hotel}) => {
    console.log("hotel",hotel)
  return (
    <div className='property-card'>
     <div className='card-image-box'>
       <img className='card-background' src = {hotel.background}  />
           
       <FaRegHeart className='fav-icon' />
       </div>

        <div className='card-details'>
        <img className='avatar-img' src={hotel.avatar} />

        <div className='review-stars'>
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
         <FaStar />
        </div>
       <div>
            <Link to="/hotel-detail"><h3>{hotel.title}</h3></Link>
            <p className='location'>{hotel.location}</p>
       </div>

       <div className='review-box'>
            <div className='review-number'>
                5/5
            </div>
            <span>Excellent</span>
            <p className='review-no'>(3 Reviews)</p>
       </div>

       <div className='price-tag'>
        <p>From :<span> {hotel.price} </span> / {hotel.time}</p>
       </div>
       </div>

    </div>
  )
}

export default PropertyCard
