import React from 'react'
import StarRateIcon from '@mui/icons-material/StarRate';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import DriveEtaOutlinedIcon from '@mui/icons-material/DriveEtaOutlined';
import BathtubOutlinedIcon from '@mui/icons-material/BathtubOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import './rental-card.scss'
import { FaStar,FaRegHeart } from "react-icons/fa";
import { Link } from 'react-router-dom';

const RentalCard = ({hotel,type}) => {
  return (
    <div className={type == 'Rental'?'property-card tour-card rental-card ':"property-card tour-card rental-card car-rental-card"}>
     
     <div className='card-image-box'>
       <img className='card-background' src = {hotel.background}  />
           
       <FaRegHeart className='fav-icon' />
       </div>

        <div className='card-details'>
        <img className='avatar-img' src={hotel.avatar} />
       <div>
            {type == 'Rental'?
               <p className='location'><LocationOnOutlinedIcon /> {hotel.location}</p>:
               <p className='location'>Convertible</p>
          }
           <Link to={type == 'Rental'?"/rental-detail":"/car-rental-detail"}><h3>{hotel.title}</h3></Link>
       </div>

      { type == "Rental"?
      <div className='facility-icons'>
        <div>
             <PeopleOutlinedIcon />
             <p>6</p>
        </div>
        <div>
             <DriveEtaOutlinedIcon />
             <p>2</p>
        </div>
        <div>
             <BathtubOutlinedIcon />
             <p>9</p>
        </div>
        <div>
             <CalculateOutlinedIcon />
             <p>260 m</p>
        </div>
       </div>:
        <div className='cars-icons'>
        <div>
             <PeopleOutlinedIcon />
             <p>6</p>
        </div>
        <div>
             <DriveEtaOutlinedIcon />
             <p>2</p>
        </div>
        <div>
             <BathtubOutlinedIcon />
             <p>9</p>
        </div>
        <div>
             <CalculateOutlinedIcon />
             <p>260 m</p>
        </div>
       </div>
       }

       { type == 'Rental' &&
          <div className='review-box'>
            <div className='review-star'>
            <FaStar style={{color:"#FFB21D",fontSize:"15px",marginRight:"5px",marginLeft:"0px"}} />  5
            </div>
            <p className='review-text'>(3 Reviews)</p>
       </div>
       }

       <div className={type == 'Rental' ?'price-tag':"price-tag price-tab-cars"}>
        <p>From :<span> {hotel.price} </span> / {hotel.time}</p>
        <p className='clock-time'></p>
       </div>
       </div>

    </div>
  )
}

export default RentalCard
