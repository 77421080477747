import React from 'react'
import './call-to-action.scss'

const CallToAction = () => {
  return (
    <div className='call-to-action container'>
      <div className='call-image'>
        <img src="https://images.pexels.com/photos/1465904/pexels-photo-1465904.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />
        </div>
        <div className='call-details'>
            <h2>Get special offers, and more from Traveler</h2>
            <p>Subscribe to see secret deals prices drop the moment you sign up!</p>
            <div className='input-subscribe'>
            <input type="text" placeholder='Email Address'/>
            <button>Subscribe</button>

            </div>
        </div>
    </div>
  )
}

export default CallToAction