import React from 'react'
import { BrowserRouter, Route,Switch } from 'react-router-dom'
import './../src/app.scss'
import DestinationPage from './View/DestinationPage'
import LandingPage from './View/LandingPage'
import RentalDetail from './View/RentalDetail'
import HotelDetail from './View/HotelDetail'
import TourDetail from './View/TourDetail'
import CarRentalDetail from './View/CarRentalDetail'

const App = (props) => {
  return (
    <div>
      <BrowserRouter>
          <Switch>
            <Route exact path="/" render={(props) => <LandingPage {...props} />} />
            <Route exact path="/destination/:id" render={(props) => <DestinationPage {...props} />} />
            <Route exact path="/hotel-detail" render={(props) => <HotelDetail {...props} />} />
            <Route exact path="/rental-detail" render={(props) => <RentalDetail {...props} />} />
            <Route exact path="/tour-detail" render={(props) => <TourDetail {...props} />} />
            <Route exact path="/car-rental-detail" render={(props) => <CarRentalDetail {...props} />} />

          </Switch>
        </BrowserRouter>
    </div>
  )
}

export default App